<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\501q_Vormessen.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                     Projekte - Vormessen für <br class="d-block d-sm none">Stopfmaschinen
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
              <v-col>
                  <v-card-text>Das digitale Vormessen für Stopfmaschinen nach RiL 824.0520 - eine Innovation aus der Praxis. Das ist unsere Behauptung...und die Steigerung der Stopfmaschinenleistung um bis zu 40% ist die Antwort unserer Kunden!</v-card-text>
              </v-col>
            </v-row>
            <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Projekt
                                        </th>
                                        <th class="text-left">
                                            Auftraggeber
                                        </th>
                                        <th class="text-left">
                                            Projektbeschreibung
                                        </th>
                                        <th class="text-left">
                                            Leistung
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, i) in componentData"
                                    :key="i"
                                    >
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.contractor }}</td>
                                        <td>{{ item.description }}</td>
                                        <td v-html="item.service"></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referencesPremeasure",
    data() {
        return {
            componentData: [
               {
                "title": "Rahmenvertrag",
                "contractor": "Eiffage Rail",
                "description": "Rahmenvertrag für Vormessleistung",
                "service": "Leistung auf Abruf"
              },
              {
                "title": "Rahmenvertrag",
                "contractor": "Schweers Bau",
                "description": "Rahmenvertrag für Vormessleistung",
                "service": "Leistung auf Abruf"
              },
               {
                "title": "Rahmenvertrag",
                "contractor": "DB Baubahn Gruppe",
                "description": "Rahmenvertrag für Vormessleistung",
                "service": "Leistung auf Abruf"
              },
              {
                "title": "DUA Frankfurt",
                "contractor": "DB Bahnbau Gruppe",
                "description": "3 Monate DUA Messungen",
                "service": "ca. 112 km"
              },
                {
                "title": "seit 2019 fester Ansprechpartner für das Vormessen der DUA Maßnahmen für PD Hamm",
                "contractor": "DB Bahnbau Gruppe",
                "description": "DUA Messungen",
                "service": "ca. 200 km"
              },
                {
                "title": "seit 2020 fester Ansprechpartner für DUA Maßnahmen für PD Hagen und PD Duisburg",
                "contractor": "DB Bahnbau Gruppe",
                "description": "DUA Messungen",
                "service": "ca. 800 km"
              },
                {
                "title": "Karlsruhe Stadtbahn",
                "contractor": "Gasthaus",
                "description": "unterirdischer Verlauf \n Stadtbahntunnel mit 1m Spurweite",
                "service": "ca 3 km"
              },
             
              {
                "title": "LGV Bretagne Pays de la Loire",
                "contractor": "Eiffage Rail",
                "description": "Hochgeschwindigkeitsstrecke LGV BPL von Le Mans nach Rennes",
                "service": "Vormessleistungen von ca. 3000 Kilometern"
              },
              {
                "title": "Köln-Rommerskirchen",
                "contractor": "Spitzke Longo GmbH",
                "description": "Gleisabsteckung Vormessleistungen Engstellendokumentation Betreuung von 2 BÜ's",
                "service": "50.000 m Vormessen"
              },
              {
                "title": "Alsenz - Rockenhausen",
                "contractor": "Strabag",
                "description": "Gleisabsteckungen Vormessleistungen",
                "service": "25.000 m Vormessen"
              },
              {
                "title": "Vormessen auf dem Hindenburgdamm",
                "contractor": "DB Maschinenpool",
                "description": "Vormessen von Gleisen, Projekt des Monats MAR 12",
                "service": "ca. 6000 m Gleis"
              },
              {
                "title": "Strecke 1810: Northeim - Scharzfeld",
                "contractor": "Eiffage Rail",
                "description": "Gleisabsteckung, Weichenabsteckung, Gleisvermarkungspläne, Netzmessung +Vormessen für Stopfmaschinen",
                "service": "ca. 220.000 m Vormessleistung "
              },
              {
                "title": "Bhf. Kelsterbach",
                "contractor": "DB Bahnbau",
                "description": "Im Bahnhof Kelsterbach liegen zwei Weichenverbindungen miteinander verschachtelt im Bogenbereich. Das Vormessen dieser komplizierten Verbindungen erfordert zusätzlich zum präzisen Aufmessen auch noch das nötige Hintergrundwissen von Weichenhöhenplan, Kegelmantelfläche sowie den Besonderheiten der letzten durchgehenden Schwelle.",
                "service": "4 Weichen plus Anstopflängen von ca. 200 Metern<br> Weichenhöhenplan"
              }
            ]
        }
    }
}
</script>