<template>
    <div>
      <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\505q_Straßenbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4 ">
                                 Projekte - Straßenbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
              <v-col>
                  <v-card-text>Auch wir ärgern uns über jeden Stau auf Autobahn oder Landstraße……aber noch mehr ärgert es uns, wenn wir nicht mit unserer Erfahrung dazu beitragen können, die geplante Bauzeit einzuhalten. Hier folgen Projekte bei denen es uns gelungen ist.</v-card-text>
              </v-col>
            </v-row>
            <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Projekt
                                        </th>
                                        <th class="text-left">
                                            Auftraggeber
                                        </th>
                                        <th class="text-left">
                                            Projektbeschreibung
                                        </th>
                                        <th class="text-left">
                                            Leistung
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, i) in componentData"
                                    :key="i"
                                    >
                                        <td>{{ item.project }}</td>
                                        <td>{{ item.contractor }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.service }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referenceTrackConstruction",
    data() {
        return {
            componentData: [
               {
                "project": "KV Terminal Logport Duisburg (20216)",
                "contractor": "Fonteyne Tief- Straßenbau GmbH",
                "description": "Baubegleitende Vermessung im Tief- u. Straßenbau",
                "service": "Baubegleitendes Abstecken für Kanäle, ?, 4.000 m Borde, Kranbahn, Bestandsdokumentation"
              },
               {
                "project": "Umgehung Meiderich BS2 (20217)",
                "contractor": "Fonteyne Tief- Straßenbau GmbH",
                "description": "Baubegleitende Vermessung im Tief- u. Straßenbau",
                "service": "Baubegleitendes Abstecken für Kanäle, ?, 3.000 m Borde, Kranbahn, Bestandsdokumentation"
              },
               {
                "project": "Umgehung Meiderich BS3 (22018)",
                "contractor": "Fonteyne GmbH",
                "description": "Baubegleitende Vermessung im Tief- u. Straßenbau",
                "service": "Baubegleitendes Abstecken für Kanäle, ?, 1.500 m Borde, Kranbahn, Bestandsdokumentation"
              },
              {
                "project": "Bochum Linie 310",
                "contractor": "Reisse Bau",
                "description": "Absteckungen für Teilabschnitt der Verlängerung Linie 310",
                "service": "Absteckungen Borde > 1000m, Absteckung Randwege > 1.000 m, Gleisabsteckung ca. 1.400 m"
              },{
                "project": "Paderborn, BAB - A33",
                "contractor": "Pollmann",
                "description": "Fahrbahnerneurung ca. 15.000 m",
                "service": "Aufmaß Urgelände, Festpunktfeld, Abrechnung, Absteckung, Abnahme, Endaufmaß"
              },
  {
    "project": "Offenthal",
    "contractor": "Kirchner",
    "description": "Absteckung für Abrechnungszwecke Absteckung der einzelnen Einbauschichten",
    "service": "4.500 m Absteckung / Abnahme im Straßenbau"
  },
  {
    "project": "Rengsdorf",
    "contractor": "Kirchner",
    "description": "Aufmaße für Abrechnungszwecke Absteckung der einzelnen Einbauschichten",
    "service": "4.500 m Absteckung / Abnahme im Straßenbau"
  },
  {
    "project": "PPP BAB A7",
    "contractor": "Bilfinger / Eurovia / Kirchner",
    "description": "Urgeländeaufmaß zur Vorermittlung von Massen",
    "service": "128.000 m Autobahn Aufnahme, Mengenermittlung"
  },
  {
    "project": "Hösbach, BAB A3",
    "contractor": "Kirchner",
    "description": "Neubau der BAB A3\nAbrechnung",
    "service": "7.000 m Mengenermittlung Absteckung / Abnahme im Straßenbau"
  },
  {
    "project": "Eisenach, BAB A4",
    "contractor": "Eurovia",
    "description": "Deckenbau der Hörselbergumfahrung A4",
    "service": "50.000 m Absteckung / Abnahme im Straßenbau"
  },
  {
    "project": "Dortmund, Phoenix-See",
    "contractor": "Frauenrath",
    "description": "In Dortmund wird eines der ehrgeizigsten Projekte der Stadterneuerung Realität. Auf einem riesigen Areal, auf dem fast 150 Jahre lang ein Stahlwerk stand, entsteht ein See, der mit seiner Wasserfläche von 24 Hektar größer als die Hamburger Binnenalster sein wird. Neben den Absteckarbeiten für die gesamten Erbauarbeiten, sind wir auch für die Abrechnung der einzelnen Positionen beauftragt.",
    "service": "Mengenermittlung Absteckung / Abnahme im Straßenbau, Absteckung / Abnahme im Ingenieurbau"
  },
  {
    "project": "Vechta, BAB A1",
    "contractor": "Beton und Monierbau",
    "description": "Betondeckenbau BAB A1\nAbrechnung",
    "service": "10.000 m Mengenermittlung, Absteckung / Abnahme im Straßenbau"
  },
  {
    "project": "Leipzig, B6",
    "contractor": "Teerbau / Tastbau",
    "description": "Neubau der B6 in Leipzig",
    "service": "14.000 m Festpunktfelder Absteckung / Abnahme im Straßenbau Absteckung / Abnahme im Ingenieurbau"
  },
]
        }
    }
}
</script>