<template>
    <div>
       <v-card flat class="mt-16 mx-10">  
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\502q_Gleisbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4 ">
                                  Projekte - Gleisbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
              <v-col>
                  <v-card-text>Schotter – Schwelle – zwei Schienen – 1435mm parallel. Hört sich einfach an. Das haben schon viele gedacht. Wir sind dabei es zu perfektionieren. Hier finden Sie Projekte zu einem unserer Spezialgebiete: </v-card-text>
              </v-col>
            </v-row>
            <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Projekt
                                        </th>
                                        <th class="text-left">
                                            Auftraggeber
                                        </th>
                                        <th class="text-left">
                                            Projektbeschreibung
                                        </th>
                                        <th class="text-left">
                                            Leistung
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, i) in componentData"
                                    :key="i"
                                    >
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.contractor }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.service }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referenceTrackConstruction",
    data() {
        return {
            componentData: [
            {
                "title": "Herne Wanne Eickel",
                "contractor": "Spitzke Longo",
                "description": "Gleis und Weichenumbau",
                "service": "50 Weichen - 9.500 m Gleis,  Engstellen, MessReg, Absteckung"
            },
            {
                "title": "Nippes ICE Werk",
                "contractor": "Wolf und Müller \n Hering Gleisbau \n Balfour Beatty \n DB Netz AG \n Walterbau \n",
                "description": "Erdbau",
                "service": "Oberleitung,  Gleisabsteckung,  Abschlussdokumentaion,  Umtrassierung/ Überführung in DBREF,  Maschinenstuerung,  GNSS Netzmessung,  DBGIS,  Erdbau und Entwässerung und Kabeltiefbau"
            },
            {
                "title": "RRX",
                "contractor": "DB Netz AG",
                "description": "Entwurfsvermessung PFB-5 in mehreren Abschnitten",
                "service": "mehrere Einzelflächen und Bauwerke,  ca. 50 Profile an neuen Signalstandorten"
            },
            {
                "title": "Bochum Linie 310",
                "contractor": "Reisse Bau",
                "description": "Gleisabsteckung ",
                "service": "ca. 700 m"
            },
            {
                "title": "Gremberg",
                "contractor": "Spitzke Longo",
                "description": "Gleisabsteckung",
                "service": "22 Weichen ca 1.000 m Gleis + Engstellen"
            },
            {
                "title": "Erlangen",
                "contractor": "Wittfeld",
                "description": "ABS Nürnberg Ebensfeld \n 4-gleisiger Ausbau",
                "service": "ca. 14.000 m Gleis, 10 Weichen,  1.500 m Zuwegung,  6.000 m Kabeltiefbau,  Neubau HP Bubenreuth,  Gleisabsteckung,  LSW,  Engstellen,  Vormessen,  GNSS Basisstation für Maschinensteuerung einrichten,  + Modelle für Maschinensteuerung,  Neubau 4 EÜ's, DB GIS"
            },
            {
                "title": "KVB",
                "contractor": "AG Gasthaus",
                "description": "Umbau Straßenbahngleise \n langfristiger Partner",
                "service": "Abnahmemessungen mit Gedo CE, Gleisabsteckung"
            },
            {
                "title": "Rheinbahn",
                "contractor": "Leonhard Weiss \n Steinbrecher",
                "description": "langfristiger Partner",
                "service": "Gleisabsteckung,  Abnahmemessungen"
            },
            {
                "title": "Bodenwerder",
                "contractor": "Willke",
                "description": "Reaktivierung der Strecke Bodenwerder - Emmerthal",
                "service": "ca. 10.000 m \n Festpunkftfeld,  Aufmaß,  Trassierung"
            },
            {
                "title": "Maschen",
                "contractor": "GUT",
                "description": "Bestandsdokumentation Zugbildungsanlage",
                "service": "ca. 90 Gleise und 159 Weichen,  Festpunktfeld"
            },
            {
                "title": "ESTW Berchtesgadener Land",
                "contractor": "",
                "description": "Aufmaße für Bahnübergangneubau",
                "service": "Aufmaß 6 BÜ's"
            },
            {
                "title": "Volmetalbahn",
                "contractor": "Hastra Bau",
                "description": "Ausbau Volmetalbahn",
                "service": "Aufmaß ca. 17.000 m"
            },
            {
                "title": "Berlin Nordkreuz",
                "contractor": "DB Netz AG",
                "description": "Überführung ins das DBREF",
                "service": "Trassierung ca. 50 Weichen 14.000 m Gleis,  Festpunktfeld,  Aufmaß mit Kombination Scanner + Gedo CE,  DBGIS,  Trassierung Dokumentation (Gleisnetzdaten)"
            },
            {
                "title": "Großbeeren",
                "contractor": "DB Netz AG",
                "description": "Aufmaß für Bestandsdokumenationen und Umbau",
                "service": "ca 20.000 m Gleis + ca. 20 Weichen, Trassierung, Weichenhöhenpläne" 
            },
            {
                "title": "GE WE Geseke",
                "contractor": "DB Netz AG",
                "description": "Gleis- und Weichenerneuerung",
                "service": "23 Weichen und 8.000 m Gleise, Aufmaß, Trassierung, Festpunktfeld mit NXO-Net, Weichenhöhenpläne"
            },
            {
                "title": "HLB",
                "contractor": "Hessische Landesbahn",
                "description": "Bestandsdokumentation",
                "service": "Aufmaß + Trassierung + FPF \n 3.7000 m"
            },
            {
                "title": "Cottbus Forst",
                "contractor": "DB Netz AG",
                "description": "Bestandsdokumentation",
                "service": "Aufmaß + Trassierung + FPF (NXO-Net) \n 20.000 m"
            },
            {
                "title": "ZBA Tempelhof",
                "contractor": "DB Netz AG",
                "description": "Bestandsdokumentation",
                "service": "55 Weichen und 11.000 m Gleise, Aufmaß + Trassierung + FPF (NXO-Net), Weichenhöhenpläne"
            },
            {
                "title": "Bf Ehrang",
                "contractor": "DB Netz AG",
                "description": "Gleis- und Weichenerneuerung",
                "service": "ca. 120 Weichen und ca. 20.000 m Gleise \n Aufmaß + Trassierung + FPF"
            },
            {
                "title": "Herford",
                "contractor": "DB Netz AG",
                "description": "Gleis- und Weichenerneuerung",
                "service": "20.000 m \n Aufmaß + Trassierung + FPF"
            },
             {
                "title": "Hbf Dortmund",
                "contractor": "DB Netz AG",
                "description": "Baubegleitende Vermessung",
                "service": "Gleisabsteckung,  Bahnsteigabsteckung,  Soll-Ist Vergleiche,  Aufmaßefür  Oberleitungsplanung,  Vormessen"
            },
             {
                "title": "Flandersbach",
                "contractor": "DB Netz AG",
                "description": "",
                "service": "21.000 m Eingleisig \n Aufmaß + Trassierung + FPF"
            },
             {
                "title": "Hannover-Göttingen",
                "contractor": "Schweerbau",
                "description": "Umbau SFS 1733 \n ca 90km",
                "service": "Gleisabsteckung,  Engstellen"
            },
             {
                "title": "Bardowick Stelle",
                "contractor": "DB Netz AG \n ",
                "description": "Gleisaufmaß und Profile",
                "service": "25.000 m Gleisaufmaß"
            },
            {
                "title": "LGV Bretagne Pays de la Loire",
                "contractor": "Eiffage Rail",
                "description": "Hochgeschwindigkeitsstrecke LGV BPL von Le Mans nach Rennes",
                "service": "Erstellung eines FP-Feldes mit ca. 1600 Neupunkten, Erstellung eines GVP_Netzes mit ca. 8000 Gleisvermarkungspunkten, Nivellementleistungen von ca. 800 Kilometern, Gleisabsteckungen von ca. 230 Kilometern sowie 100 Weichen, Vormessleistungen von ca. 3.000 Kilometern, Gerätschaften: Gedo CE vorsys, Gedo CE, Festpunktfelder, Gleisabsteckung / Abnahme Gleisbau, Trassenplan"
            },
            {
                "title": "Feste Fahrbahn Roitzsch",
                "contractor": "Max Bögl",
                "description": "Gleisabnahmevermessung der Festen Fahrbahn, Projekt des Monats JUN/JUL 13",
                "service": "30.000 m Gleis, 12 Weichen, Gedo CE\n    \n        Feste Fahrbahn"
            },
            {
                "title": "Köln-Rommerskirchen",
                "contractor": "Spitzke Longo GmbH",
                "description": "Gleisabsteckung, Vormessleistungen, Engstellendokumentation, Betreuung von 2 BÜ's",
                "service": "12.000 m Gleisabsteckung, 50.000m Vormessen, 87 Engstellen (Erkundung/Messung/Dokumentation), Gleistragplatten, Gedo CE vorsys, Gleisabsteckung / Abnahme Gleisbau"
            },
            {
                "title": "Alsenz - Rockenhausen",
                "contractor": "Strabag",
                "description": "Gleisabsteckungen, Vormessleistungen",
                "service": "8.500 m Gleisabsteckung, 25.000 m Vormessen, Gleisabsteckung / Abnahme Gleisbau"
            },
            {
                "title": "ZBA Halle",
                "contractor": "GP Papenburg / Balfour Beatty Rail",
                "description": "Urgeländeaufmaß / Rückbauplan, Gleisabsteckung, Maschinensteuerung, Kabeltiefbau, Entwässerung, Gleisvermarkung, Projekt des Monats Mai 13",
                "service": "37.000 m Gleis, 37.000 m Maschinensteuerung, 492 Kabelschächte, 649 Entwässerungsschächte, 315 GVP, Gleisabsteckung / Abnahme Gleisbau, Trassenplan, Absteckung / Abnahme im Ingenieurbau"
            },
            {
                "title": "Luxemburg Differdange",
                "contractor": "CFL Luxemburg",
                "description": "Lageplan und Entwurfsvermessung für Trassierungsarbeiten, Projekt des Monats JAN 13",
                "service": "4.500 m Gleis (freie Strecke), 8.000 m Gleis (Bf. Differdange), Querprofilerstellung im 25-m-Raster, Trassierung, Entwurfsvermessung"
            },
            {
                "title": "Flächenfreisetzung Aachen",
                "contractor": "Hering Gleisbau",
                "description": "Netzverdichtung, Gleisabsteckung, Oberleitungsarbeiten, Projekt des Monats JUL/AUG 12",
                "service": "7.100 m Gleisabsteckung, 25 Weichen, 80 Masten, Festpunktfelder, Gleisabsteckung / Abnahme"
            },
            {
                "title": "Knoten Dessau/Rosslau",
                "contractor": "Spitzke SE",
                "description": "vermessungstechnische Bestandsdokumentation im DB_REF, Projekt des Monats APR 12",
                "service": "ca. 25.000 m Gleis,  Festpunktfelder, Trassierung,  Weichenhöhenplan"
            },
            {
                "title": "HH-Billwerder: Erweiterung des Umschlagbahnhofes",
                "contractor": "Möbius-Bau",
                "description": "Erweiterung des bestehenden Umschlagbahnhofes HH-Billwerderum das neue Terminal 3. Projekt des Monats APR 11",
                "service": "ca. 3.500 m Gleisbau, 3 Weichen, ca. 1.200 m Kranbahn, ca. 40.000 qm Stellplätze für LKW, Gleisabsteckung / Abnahme Gleisbau, Deckenbuch,  Absteckung / Abnahme im Straßenbau"
            },
            {
                "title": "Strecke 1810: Northeim - Scharzfeld",
                "contractor": "Eiffage Rail",
                "description": "Gleisabsteckung, Weichenabsteckung, Gleisvermarkungspläne, Netzmessung, Vormessen für Stopfmaschinen",
                "service": "ca. 33.000 m Gleisabsteckung, ca. 220.000 m Vormessleistung, Trassenplan"
            },
            {
                "title": "Stelle - Lüneburg",
                "contractor": "Möbius",
                "description": "Der Abschnitt Stelle - Lüneburg ist Teil der ICE-Strecke von Hamburg  nach Hannover. Mit den anstehenden Baumaßnahmen wird der Abschnitt  Lüneburg - Stelle dreigleisig, und der Abschnitt Stelle - Ashausen sogar  viergleisig ausgebaut.  Projekt des Monats: März 10",
                "service": "15.000 m,  6 Brücken, Gleisabsteckung / Abnahme Gleisbau, Entwurfsvermessung"
            },
        ]
        }
    }
}
</script>