<template>
    <div>
        <v-card flat class="ma-0 pa-0">
            <v-row>
                <v-col lg="4" v-if="(obj.id % 2 === 1)">
                    <v-carousel>
                        <v-carousel-item v-for="(picture, i) in this.obj.pathToPicture" :key="i">
                            <expandable-image close-on-background-click=true :name="obj.id" contain :src="require('../../assets/images/monthProjects/' + picture)" max-height="600" max-width="600"></expandable-image>
                        </v-carousel-item> 
                    </v-carousel>
                </v-col>
                <v-col lg="8">
                    <v-card-text v-html="obj.text"></v-card-text>
                </v-col>
                <v-col lg="4" v-if="(obj.id % 2 === 0)">
                    <v-carousel-item v-for="(picture, i) in this.obj.pathToPicture" :key="i">
                                <expandable-image close-on-background-click=true :name="obj.id" contain :src="require('../../assets/images/monthProjects/' + picture)" max-height="600" max-width="600"></expandable-image>
                    </v-carousel-item>
                </v-col>    
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
    name:"BaseNews",
   
    props: {
        obj: { type: Object},
    }
}
</script>