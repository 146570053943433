<template>
    <div>
       <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\pexels-maria-orlova-4947408.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-h5 text-lg-h4" style="color: black">
                                    News
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col class="mx-4">
                    <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel v-for="monthProject in monthProjects"
                            :key="monthProject.id" >
                            <v-expansion-panel-header> 
                                    <v-card flat :ref="'panel_' + monthProject.id">
                                        <v-card-title class="text-body-1 text-md-h6 wrap-normal">
                                            {{monthProject.date}}:  {{monthProject.title}}
                                        </v-card-title>
                                        <v-card-text>
                                            {{monthProject.shortText}}
                                        </v-card-text>
                                    </v-card>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col>
                                    <BaseNews :obj="monthProject"></BaseNews>
                                </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
       </v-card>
    </div>
</template>

<script>
import monthProjects from '@/configs/monthProjects';
import BaseNews from'../News/BaseNews';

export default {
    name:"News",
    metaInfo: {
      title: 'News',
    },
    data: () => ({
        monthProjects: monthProjects,
        panel: [],
    }),
    components: {
        BaseNews
    },
    mounted() {
        if (this.$route.params.id){
            this.panel.push(this.$route.params.id * 1)
            console.log(this.panel);
            let ref = this.$refs['panel_' + this.$route.params.id * 1][0].$el;
            setTimeout(() => {
                this.$vuetify.goTo(ref);
            }, 500);
        }
    },
    beforeDestroy(){
        this.panel = [];
    }
}
</script>

<style scoped>
    .hover:hover {
        color: var(--primary-color) !important;
        cursor: pointer;
        transition: 0.6s;
    }
</style>