<template> 
   <div class="navitem">
       <v-container class="pa-0 mx-2 my-0" v-if="route.hasSubRoutes != 0"> 
            <v-menu open-on-hover offset-y nudge-bottom="10" close-delay="100">
                    <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs"
                            v-on="on"
                            :href="'/#' + route.path"
                            class='a'>
                            {{route.displayName}}
                        </a>
                    </template>
                    <v-card>
                        <v-list-item v-for="subroute in route.children" :key="subroute.displayName" :href=" '/#' + route.path + subroute.path">
                          {{subroute.displayName}}
                        </v-list-item>
                    </v-card>
            </v-menu>
        </v-container>    
        <v-container class="pa-0 mx-2 my-0 a" v-else> 
                    <a class='a' :href=" '/#' + route.path">
                        {{route.displayName}}
                    </a>
        </v-container>
  </div>
</template>

<script>
export default {
    name: "NavItem",
    data() {
        return {
        show: false,
        hoverTimer: null
        };
    },
    props: ["route"],
}
</script>

<style scoped>
    .navitem {
        border-bottom: none;
        color: #000;
        display: block;
        font-size: 1.2em;
    }
    .navitem:hover {
        cursor: pointer;
        background: lightgray;
        transition: 0.3s;
        border-radius: 3px;
    }
    .v-tooltip__content {
        pointer-events: initial;
    }
    .a {
        text-decoration: none;
        color:black !important;
        font-family: 'Inter', sans-serif;
    }
</style>