<template>
    <div>
        <v-card flat class="mx-10 mt-16 ma-md-16"> 
            <v-row class=""> 
                <v-col>
                    <v-card-title class="text-h5 text-lg-h2 ">
                        Projekte
                    </v-card-title>
                </v-col>
            </v-row>
            <v-card-text>
                <v-row>
                    <v-col class="text-body-2 text-sm-subtitle-1 text-justify">
                       Wir können Ihnen ja viel erzählen. Machen wir auch. Aber als Liste, sonst wird der Platz zu knapp.
                    </v-col>
                </v-row>
                <v-row class="pt-7 pt-md-0" >
                    <v-col lg="6" xs="12" v-for="(service, i) in services" :key="i">
                        <SingleReference  style="max-height: 500px" :service="service"></SingleReference>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import projectArray from '../../configs/projects';
import SingleReference from './SingleReference'

export default {
  name: 'References',
  components: {
      SingleReference,
  },
  metaInfo: {
      title: 'Projekte',
  },
  data() {
      return {
        title: "Leistungen",
        services: projectArray
      }
  },
}
</script>

<style scoped>
    .data:hover {
        cursor: pointer;
        background: lightgray;
        transition: 0.6s;
    }
</style>
