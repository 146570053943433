<template>
  <v-footer
    dark
    padless
    class="px-0 px-lg-16 py-3"
    >
      <v-row class="ma-0 text-caption text-lg-caption" >
            <v-spacer class="d-none d-md-block"></v-spacer>
            <v-col cols="12" sm="4" md="4" class="text-sm-right text-center">
              <p> IBH - Ingenieurbüro Herzbruch GmbH </p>
              <p> Mittelstrasse 36 </p>
              <p> 58332 Schwelm </p>
              <p> Deutschland </p>
              <p> Tel. +49 (0) 2336-4099-0 </p>
              <p> Fax +49 (0) 2336-4099-20 </p>
              <p> info@ibh-vermessung.de </p> 
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <p> Vertretungsberechtigter: Dipl.-Ing. Frank Herzbruch</p>
              <p> Vertretungsberechtigter: Dipl.-Ing. Frank Dahlhoff</p>
              <p> Verantwortlich für den Inhalt:</p>
              <p>Dipl.-Ing. Frank Herzbruch, Dipl.-Ing. Patrick Walke</p>
              <p> Umsatzsteuer-ID: DE 164902028</p>
              <p> Handelsregister: HRB 6204</p>
              <p> Zuständiges Gericht: Amtsgericht Hagen</p>
            </v-col>
            <v-col cols="12" sm="auto" md="4" class="text-center text-sm-left">
              <p class="hover"> <a href="/#/impressum">Impressum </a></p>
              <p class="hover"> <a href="/#/datenschutz"> Datenschutz </a></p>
              <p class="hover"> <a href="/#/downloads"> Downloads </a></p>
              <p class="hover" ><a href="https://www.facebook.com/IBH-Ingenieurb%C3%BCro-Herzbruch-GmbH-106690350942500/"> Facebook </a></p>
              <p class="hover"> <a> LinkedIn </a> </p>
              <p class="hover"> <a> Xing </a> </p>
              <p class="hover"> <a href="/#/legal"> Rechtliche Hinweise </a> </p>
            </v-col>
            <v-spacer class="d-none d-md-block"></v-spacer>
      </v-row>
      <v-row class="text-lg-caption py-8" style="width:100%">
        <v-col class="text-center">
          Made with <v-icon>mdi-heart</v-icon> and <v-icon>mdi-coffee</v-icon> by Moritz Herzbruch 
        </v-col>
      </v-row>
  </v-footer>
</template>
<script>
export default {
    name:"Footer",
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
    computed: {
      width() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 400
            case 'sm': return 400
            case 'md': return 400
            case 'lg': return 1400
            case 'xl': return 1400
            default: return 1400;
        }
      }
    }
}
</script>

<style scoped>
    .color {
        background: #302F30;
        width:100%;
    }
    a {
      text-decoration: none  !important;
      color: white !important;
    }
    p {
      padding: 0;
      margin: 0;
      line-height: 25px;
    }
    p.hover:hover {
      text-decoration: underline;
      cursor: pointer;
    }
</style>