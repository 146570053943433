<template>
    <v-card flat class="mt-16 px-6"> 
        <v-row> 
            <v-col xl="6"  lg="6" sm="12" xs="12">
                    <AboutUsText></AboutUsText>
            </v-col>
            <v-col cols="12" xl="6" lg="6" sm="12" xs="12" class="column">
                    <Anfahrt></Anfahrt>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Anfahrt from "./Anfahrt";
import AboutUsText from "./AboutUsText";
export default {
    name: "AboutUs",
    components: {
        Anfahrt,
        AboutUsText
    },
    metaInfo: {
      title: 'Über Uns',
       meta: [
          {name: 'description', content:  'Vormessen. Gleisbau. Ingenieurbau. Maschinensteuerung. Straßenbau. keinswegs vermessen.'}
      ] 
    }
}
</script>

<style scoped>
.column {
    height: inherit;
    background: var(--secondary-color);
}
</style>