<template>
    <div>
        <v-app-bar 
        class="pa-0 ma-0"
        color="white"
        flat
        height="130"
        elevation="14">
            <v-card flat style="width: 100%">
                <v-row>
                    <v-spacer class="d-none d-md-block"></v-spacer>
                    <v-col md="12" lg="11" xl="8"  class=" ml-xl-7 ml-lg-7 ml-md-3 text-lg-caption d-none d-md-block">
                        <a class="mr-2" href="/#/datenschutz"> Datenschutz </a>
                        <a class="mr-2" href="/#/impressum"> Impressum </a>
                        <a href="/#/downloads"> Downloads </a>
                    </v-col>
                    <v-spacer class="d-none d-md-block"></v-spacer>
                </v-row>
                <v-row align="center">
                    <v-spacer class="d-none d-md-block"></v-spacer>
                    <v-col md="7" lg="5" xl="4" class="col-auto">
                        <div class="d-none d-md-block" fill-height>
                                <NavBar :routes="routes"></NavBar>
                        </div>
                        <div xs="auto" sm="auto" class="d-md-none">
                            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
                        </div>
                    </v-col>
                    <v-col xs="auto" sm="6" md="3" lg="3" xl="2" class="text-end text-caption text-md-h6 px-0"><v-icon :size="telephoneHeight" color="black" class="pr-3 d-none d-sm-inline-flex">mdi-phone</v-icon>+49 (0) 2336-4099-0</v-col>
                    <v-col xs="auto" sm="auto" md="2" lg="3" xl="2" class="col-auto ml-auto">
                        <a href="/">
                            <v-img slot="img" contain src="@/assets\images\ibh.jpg"
                                            :height="height"
                                            :width="width">
                            </v-img>
                        </a>
                    </v-col>
                    <v-spacer class="d-none d-md-block"></v-spacer>
                </v-row>
            </v-card>
        </v-app-bar>
        <v-navigation-drawer
        v-model="drawer"
        app
        temporary>
            <v-card flat>
                <v-row class="px-6 py-2 flex-column justify-center">
                    <v-col>
                        <v-list>
                            <template v-for="route in routes">
                                <template v-if="route.hasSubRoutes == 0">
                                    <v-list
                                        :id="route.displayName"
                                        :key="route.path">
                                        <v-list-item-title class="pl-4" ><a :href="'#' + route.path">{{route.displayName}}</a></v-list-item-title>
                                    </v-list>
                                </template>
                                <template v-else>
                                    <v-list-group
                                        :id="route.displayName"
                                        :key="route.path">
                                        <template v-slot:activator>
                                            <v-list-item-title class="pa-0"> {{route.displayName}} </v-list-item-title>
                                        </template>
                                        <template v-for="child in route.children">
                                            <v-list-item
                                                :id="child.displayName"
                                                :key="child.path"
                                                :href=" '/#' + route.path + child.path"
                                                >{{ child.displayName }}
                                            </v-list-item>
                                        </template>
                                    </v-list-group>
                                </template>
                            </template>
                        </v-list>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col >
                        <v-list>
                            <v-list-item class="small-entries" href="/#/datenschutz">Datenschutz</v-list-item>
                            <v-list-item class="small-entries" href="/#/impressum">Impressum</v-list-item>
                            <v-list-item class="small-entries" href="/#/downloads">Downloads</v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
import NavBar from '@/components/AppBar/NavBar.vue'
export default {
    name: "AppBar",
    components: {
        NavBar
    },
    data: () => ({
        drawer: false,
        group: null,
    }),
    props: ["routes"],  
    watch: {
      group () {
        this.drawer = false
      },
    },
    computed: {
        height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 40
                case 'sm': return 40
                case 'md': return 40
                case 'lg': return 60
                case 'xl': return 80
                default: return 70;
            }
        },
        width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 150
                case 'sm': return 150
                case 'md': return 150
                case 'lg': return 200
                case 'xl': return 270
                default: return 250;
            }
        },
        telephoneHeight() {
            switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 20
                    case 'sm': return 20
                    case 'md': return 34
                    case 'lg': return 34
                    case 'xl': return 34
                    default: return 34;
            }
        },
    },
    methods: {
        menuItems () {
            return this.menu
        }
    },
}
</script>

<style scoped>
   .v-list-item__title {
       height: 30px;
       letter-spacing: 1px;
   }
   .small-entries{
       font-size: 14px;
   }
</style>