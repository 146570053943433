<template>
    <div class="mt-16">
        <v-row class="title ma-0" justify="center">
            <span class="text-h5 text-lg-h3"> Über Uns </span>
        </v-row>
        <v-spacer></v-spacer>
        <v-row class="pa-6 pa-lg-16 justify-center">
            <v-col>
                <v-row v-for="(number, i) in numbers" :key="i" class="pb-3">
                    <v-spacer class="d-none d-lg-block"></v-spacer>
                    <v-col class="pr-3 pr-lg-5 text-h4 text-lg-h1 text-end" lg="5" xs="12">
                        {{number.number}}
                    </v-col>
                    <v-col class="text-caption text-lg-h6 d-flex flex-column justify-end pb-2" lg="5"  xs="12">
                        {{number.title}}
                    </v-col>
                    <v-spacer class="d-none d-lg-block"></v-spacer>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name:"AboutUs",
    data: () => ({
        numbers: [
            {
                number: "> 100.000",
                title: "Kilometer vermessen"
            },
            {
                number: "500+",
                title: "Projekte pro Jahr"
            },
            {
                number: "7",
                title: "Länder"
            },
            {
                number: "1",
                title: "Büro"
            },

        ]
    }),
    methods: {
    },
    
}
</script>

<style lang="scss" scoped>

</style>