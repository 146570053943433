<template>
  <v-app>
    <AppBar :routes="routes"></AppBar>

    <!-- Sizes your content based upon application components -->
    <v-main >

      <!-- Provides the application the proper gutter -->
      <v-container class="pa-0 mb-16">
          <router-view :routes="routes"></router-view>
      </v-container>
    </v-main>
    <Footer>
    </Footer>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar/AppBar.vue'
import Footer from './components/Footer.vue'
import routeArray from './router/routeArray.js'

export default {
  name: 'App',
  components: {
    AppBar,
    Footer
  },
  data: () => ({
    "routes": routeArray
  }),
  computed: {
    
  }
};
</script>

<style scoped>
  @import './assets/global.css';
</style>
