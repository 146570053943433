<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\503q_Ingenieurbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4 ">
                                   Projekte - Ingenieurbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>Ein Ingenieurbüro im Ingenieurbau. Gibt es da noch mehr zu zu sagen? Hier ein Querschnitt aus unseren bisherigen Projekten im Ingenieurbau.</v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Projekt
                                    </th>
                                    <th class="text-left">
                                        Auftraggeber
                                    </th>
                                    <th class="text-left">
                                        Projektbeschreibung
                                    </th>
                                    <th class="text-left">
                                        Leistung
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item, i) in componentData"
                                :key="i"
                                >
                                    <td>{{ item.project }}</td>
                                    <td>{{ item.contractor }}</td>
                                    <td v-html="item.description"></td>
                                    <td v-html="item.service"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referencesEngineering",
    data() {
        return {
            componentData: [
               {
                "project": "A 38",
                "contractor": "Hentschke Bau",
                "description": "Brückenneubau Bereich Halle/Saale",
                "service": "Absteckung und Abnahme"
              },
                {
                "project": "A 38",
                "contractor": "Hentschke Bau",
                "description": "Brückenneubau Bereich Hamm",
                "service": "Absteckung und Abnahme"
              },
                {
                "project": "A 38",
                "contractor": "Biltinger und Berger",
                "description": "Thyratalbrücke",
                "service": "Absteckung und Abnahme"
              },
                {
                "project": "Saadiyat Bridge in Abu Dhabi",
                "contractor": "Dywidag ",
                "description": "Brückenneubau vom Festland zu Saadiyat Island",
                "service": "Koordination der Vermessung, Qualitätsmanagement"
              },
              {
                "project": "Viktoriabrücke in Bonn",
                "contractor": "Echterhoff",
                "description": "Brückenneubau",
                "service": "Baubegleitende Vermessung WL Überbau, Setzungsmessung, 3D-Scan, Aufmaß, Ausgleichgradiente"
              },
              {
                "project": "Tel-Aviv-Brücke, Köln",
                "contractor": "Leonhard Weiss",
                "description": "Brückensanierung",
                "service": "3D-Scan, Aufmaß, Ausgleichgradiente"
              },
              {
                "project": "Angerbachtalbrücke, A44",
                "contractor": "AMAND",
                "description": "Neubau Autobahnbrücke ",
                "service": "400 m Bauwerkslänge, Grundlagennetz, Absteckungen WL, Absteckungen Pfeiler, Regelmäßige Prüfung FP-Feld, baubegleitende Vermessung, Ausgleichgradiente, Setzungsmessung"              
                },
            {
                "project": "Viersen, Brückeneinschub",
                "contractor": "Echterhoff",
                "description": "Brückenverschub unter 4 Gleise<br>Projekt des Monats JUN 13",
                "service": "24h Betreuung über 10 Tage während des Verschubes"
            },
            {
                "project": "HH-Billwerder: Erweiterung des Umschlagbahnhofes",
                "contractor": "Möbius-Bau",
                "description": "Erweiterung des bestehenden Umschlagbahnhofes HH-Billwerderum, das neue Terminal 3.<br>Projekt des Monats APR 11",
                "service": "ca. 3.500 m Gleisbau<br>3 Weichen <br>ca. 1.200 m <br>Kranbahn <br>ca. 40.000 qm  <br>Stellplätze für LKW"
            },
            {
                "project": "Duisburg-Vinckebrücken",
                "contractor": "Echterhoff",
                "description": "Betreung der neu zu bauenden Vinckebrücken, die die bestehende Oberbürgermeister-Lehr-Brücke ersetzen wird.",
                "service": "Brückenlänge: 100 m<br>Netzausdehnung: 300 x 500 Meter"
            },
            {
                "project": "Dortmund, Phoenix-See",
                "contractor": "Wayss & Freitag",
                "description": "Ingenieurbauwerke im Bauvorhaben",
                "service": ""
            },
            {
                "project": "Lünen, Kraftwerk",
                "contractor": "Oevermann",
                "description": "Neubau der Kohlesilos im Kraftwerk Lünen",
                "service": "Absteckung und Abnahme der ? "
            },
            {
                "project": "Leverkusen, Rathaus-Galerie",
                "contractor": "Oevermann / ECE",
                "description": "Durchführung aller Absteckarbeiten für die Neue Stadtmitte Leverkusen.Die \"Rathaus-Galerie\" mit ihrer weithin sichtbaren gläsernen Rotunde umfasst 3 Etagen und 22.600 qm Verkaufsfläche. Das 200-Millionen-Euro Projekt wurde von der IBH vermessen",
                "service": "22.600 qm"
            },
        ]
        }
    }
}
</script>