<template>
    <div>
        <v-card flat color="secondary-color px-6" >
            <v-card-title style="color: white"  class="text text-lg-h2 text-h5"> Anfahrt </v-card-title>
            <v-card-text>
                <v-row align="center">
                    <v-col xs="12" lg="6" >
                        <v-img width="400" src="@/assets\images\IBH_Schwelm_Eingang.jpg"></v-img>
                    </v-col>
                    <v-col cols="12" lg="6">
                            <p> <v-icon size="24px" color="white" class="pr-3">mdi-city</v-icon> Mittelstraße 36, 58332 Schwelm</p>
                            <p> <v-icon size="24px" color="white" class="pr-3">mdi-at</v-icon> info@ibh-vermessung.de</p>
                            <p> <v-icon size="24px" color="white" class="pr-3">mdi-phone</v-icon> +49 (0) 2336-4099-0</p>
                            <p> <v-icon size="24px" color="white" class="pr-3">mdi-fax</v-icon> +49 (0) 2336-4099-20</p>
                            <p> <v-icon size="24px" color="white" class="pr-3">mdi-highway</v-icon> BAB A1, Ausfahrt Schwelm</p>
                    </v-col>    
                    <v-col xs="12" lg="12">
                        <v-row class="d-flex flex-column">
                            <v-col col="12">
                                <vl-map v-bind:controls="false" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" style="height: 300px">
                                    <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>
                                    <vl-layer-tile id="osm">
                                        <vl-source-osm></vl-source-osm>
                                    </vl-layer-tile>
                                    <vl-geoloc @update:position="geolocPosition = $event">
                                        <vl-feature  id="position-feature">
                                            <vl-geom-point :coordinates="[812039.772006223,6672818.444163422]"></vl-geom-point>
                                        </vl-feature>
                                    </vl-geoloc>
                                </vl-map> 
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

export default {
    name: "Anfahrt",
    data: () => ({
        zoom: 18,
        center: [812004.6090623316, 6672798.725875474],
        rotation: 0,
    })
}
</script>

<style scoped>
    * {
        font-family: "Inter", sans-serif;
        color: white;
    }
    .line-height {
        line-height: 30px;
    }
     .secondary-color {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
    }
</style>