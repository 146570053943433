<template>
    <v-card flat > 
        <v-row > 
            <v-col>
                    <v-card-title class="text-h5 text-lg-h2 ">
                        Über Uns
                    </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-text>
                    <div id="viewer_about_us">
                        <p>Das Ingenieurbüro Herzbruch (IBH) mit Sitz in Schwelm ist eine der ersten Adressen, wenn es um die vielfältigen Aufgaben im Bereich der Vermessung oder Abrechnung, sowie der Überwachung und Planung von Baumaßnahmen geht.</p>
                        <p>Als Spezialist im Bereich des Bahnstrecken- und Straßenbaus verfügt unser Büro mit seinen knapp 60 Mitarbeitern über ein großes Erfahrungspotential. Als Tochtergesellschaft der <a href="https://www.sitech.de/"> <b>SITECH GmbH</b> </a> (ab dem 01.08.2019) setzen wir seit mittlerweile 28 Jahren alles daran Präzision und Flexibilität in Ihre Dienste zu stellen.</p>
                        <p>Als Rahmenvertragspartner der <span class="thickbox">DB NETZ AG sowie auch der DB Bahnbaugruppe</span>&nbsp;ist unser Büro eine feste Größen auch im Bereich des Gleisbaus. Von der Festpunktübergabe bis zur <span class="thickbox">Bestandsdokumentation</span>. Von der Maschinensteuerung bis hin zur Massenabrechnung. Von NXO-Net über AVANI bis hin zum Vormessen. Unsere beiden Standorte im Ruhrgebiet sowie im Raum Leipzig sorgen für eine gute Flächenabdeckung bei den verschiedensten Projektierungen.</p>
                        <h2>Ihre Ansprechpartner bei der IBH:</h2>
                        <br>
                        <p><em><strong>Geschäftsführung: Dipl.-Ing. EURAIL-ING Frank Herzbruch (02336 / 4099-0)</strong><br></em>Kaufmännische Leitung, technische Beratung, Marketing, Kalkulation, Qualitätsmanagement, Personalwesen<br></p>
                        <p><em><strong>Technischer Leiter: Dipl.-Ing. EURAIL-ING Patrick Walke (0152 / 53409956)</strong><br></em>Technische Leitung, Projektleitung, Entwicklung von Anwenderlösungen, Qualitätsmanagement, Arbeitsvorbereitung, Kalkulation<br></p>
                        <p><em style="line-height: 1.5em;"><strong>Innendienstleiter: Martin Hofmann (02336 / 4099-0)<br></strong></em><span style="line-height: 1.5em;">CAD-Bearbeitung, Projektvorbereitung, Qualitätsmanagement</span></p>
                        <p><em><strong>Leiter Maschinensteuerung: Christian Tomala (0160 / 6823489)</strong><br></em>Digitale Geländemodelle, Massenermittlungen, Arbeitsvorbereitung<br></p>
                        <p><em><strong>Disponent: Sebastian Busch (02336 / 4099-11)</strong><br></em>Disposition von Mitarbeitenden, Arbeitsmittel und Material</p>
                        <p><em><strong>Leiter Engstellenvermessung /</strong><strong>Minderwertberechnung DSK: Dipl.-Ing. Dirk Heinemann (0152 / 53409964)</strong><br></em>Koordination und Durchführung der Engestellenmessungen, Messung und Auswertung von Hausschieflagen</p>
                        <p><em><strong>Leiter Vormessen: Florian Worofka (0152 / 53409956)</strong><br></em>Koordination der Vormesstrupps, Arbeitsvorbereitung, Auswertungen</p>
                        <br>
                        <p>Wir sind stolz darauf, über unsere Mitarbeitern sagen zu können, dass sie sich in all den Einsatzgebieten unseres Büros auskennen. Durch die regelmäßigen internen Schulungen findet ein Wissensaustausch statt, der Ihnen als Baufirma wiederum zu Gute kommt.</p>
                        <p>Unser Wissen verteilt auf allen Schultern erlaubt es uns so flexibel zu reagieren, wie es der Baubetrieb ab und an fordert.</p>
                        <p>Wir wissen, dass „ab und an" schnell zu einem „jetzt und sofort" werden kann. <br>Unsere Truppkapazität von 14 bis 16 Außendiensttrupps bringt uns die Flexibilität, die der Markt von uns verlangt.</p>
                        <p>Unterstützt werden unsere Ingenieure durch unsere Messgehilfen, die mit ihrer langjährigen Außendiensterfahrung, sowie dem geschulten Auge und dem wachen Blick auch mal das sehen, was der Truppführer auch mal nicht sehen kann.</p>
                        <p>Aber wie hilflos wäre unser Büro sowohl am Telefon als auch in der Buchhaltung ohne die wichtige Zuarbeit unserer Assistentin:</p>
                        <p><em><strong>Assistenz der Geschäftsführung: Petra Jung (02336 / 4099-0)</strong></em><br>Buchhaltung, Mahnwesen, Personalwesen</p>
                    </div>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "AboutUsText"
}
</script>