<template>
    <div>
       <v-card flat class="mt-16 mx-md-10 mx-8"> 
             <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/roadconstruction" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\305_Straßenbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                  Straßenbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(data, i) in componentData"
                                :key="i">
                                <v-expansion-panel-header> 
                                        <v-card flat>
                                            <v-card-title v-html="data.title" class="text-body-1 text-md-h6 text-justify wrap-normal">
                                            </v-card-title>
                                            <v-card-text v-html="data.shortText">
                                            </v-card-text>
                                        </v-card>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col v-html="data.longText">
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesRoadConstruction',
  components: {
  },
  data() {
      return {
        title: "Leistungen/Services - Straßenbau",
        componentData: [
            {
                'title': 'Festpunktfelder',
                'shortText': 'Das Festpunktfeld ist unabhängig vom jeweiligen Bauprojekt das A und O für eine Richtlinien-konforme Bearbeitung des Projektes. Hier entscheidet sich bereits vor dem ersten Spatenstich ob die Einhaltung der Bautoleranzen überhaupt möglich ist!',
                'longText': 'Gleisbau - Ingenieurbau - Straßenbau<br><br>' +

                            'Das verantwortungsvolle Zusammenspiel von "So genau wie nötig" mit "So genau wie möglich" ist der Anspruch, dem wir uns jeden Tag stellen!<br><br>' + 

                            'Die Grundlage einer jeden Baumaßnahme bildet ein präzises Festpunktfeld. Je höher die Anforderungen an das Bauvorhaben, desto höher auch der Genauigkeitsanspruch an das jeweilige Festpunktfeld. Ob der Anschluss des DB-REF-System der DB AG per GPS erforderlich ist, oder das terrestrische Einmessen von Bodenpunkten zur Verdichtung des bestehenden Festpunktfeldes oder gar ein Höhennetz zum Anschluss an das DHHN 92 benötigt wird, sowohl von Know-How als auch vom Equipment sind wir hier bestens aufgestellt und können mit aktuellster Technologie höchste Präzision mit der Wirtschaftlichkeit der Baustelle kombinieren.<br><br>' +

                            'Die LV-Position "Übernahme Festpunktfeld" ist unserer Meinung nach die wichtigste und verantwortungsvollste Position im Bereich der Vermessung überhaupt!<br><br>',
            },
             {
                'title': 'Mengenermittlung',
                'shortText': 'Die scheinbar belanglosen Fragen bestimmen schon in der Anfangsphase eines Projektes die Richtung:<br><br>' + 

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"',
                'longText': '„Benötigen Sie die Abrechnung nach Querprofilen oder über ein DGM?<br><br>"' +

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"<br><br>' +

                            'Unsere Mengenermittlungsaufträge beginnen in der Regel mit einem qualifizierten Fachgespräch zwischen unserem Mitarbeiter und Ihrem zuständigen Bauleiter, Abrechner oder auch Bauüberwacher. Hier legen wir bereits im Vorfeld dar, wo die Unterschiede der Berechnung liegen, bzw. zeigen Ihnen anhand der Vorbemerkungen auf, welche Menegnermittlungsart gewünscht und letztendlich auch vom Auftraggeber akzeptiert wird.<br><br>'+

                            'Diese fachliche Unterstützung ist unser Service an Sie, die den weiteren Arbeitsprozess, sowie auch den Zeitraum der Mengenermittlung einer Baumaßnahme in einem überschaubaren Rahmen hält.<br><br>'+

                            'Und, dass Zeit Geld ist, brauchen wir Ihnen nicht mehr zu erzählen!',
            },
             {
                "title": "Deckenbuch",
                "shortText": 'Ein Zahlenspiel der Fahrbahnbreite mit der Querneigung und den absoluten Höhen....<br>' +
                              'Hierüber definiert sich die Linienführung der Achse.',
                "longText": 'Das Deckenbuch beschreibt die Linienführung einer Fahrbahndecke entlang einer Achse. Es ist eine spezielle Darstellung, die es erlaubt mehrere Spuren zu definieren. Die Spuren wiederum beinhalten die Angaben von Breiten-, Querneigungs-, und Höhendifferenzen, sowie absoluten Höhen der Fahrbahndecke.<br><br>'+

                            'Die gängigen Datenfomate können hier von unseren Mitarbeiter verarbeitet werden. Sowohl der Import als auch der Export gehört zu unseren Standardleistungen im Bereich des Straßenbaus.<br>'
            },
             {
                "title": 'Absteckung / Abnahme im Straßenbau',
                "shortText": 'Die Nutzung der neuesten Technologien in der Vermessungsabteilung garantiert Ihnen einen zügigen Bauablauf.<br>' +
                            'Es fängt bei den kleinen Dingen an wo sich bereits unser Know-How widerspiegelt: auch die Wahl der Vermarkungsart enstcheidet über die Langlebigkeit auf der Baustelle<br>',
                "longText": 'Die Nutzung der neuesten Technologien in der Vermessungsabteilung garantiert Ihnen einen zügigen Bauablauf. Sowohl die Genauigkeit als auch die Geschwindigkeit der Vermessungsarbeiten ist der entscheidende Faktor dafür, dass u.a. der Deckenfertiger nur noch zum auftanken stehen bleiben muss.<br>' +

                            'Wir können die Vermessung im Straßenbau nicht mehr neu erfinden - aber die bestehende Technik auf unsere Bedürfnisse besser abstimmen.',
            },
        ]
      }
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>
