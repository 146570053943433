<template>
<div>
    <div class="parallax"></div>
</div>
</template>

<script>
export default {
    name:"Parallax"
}
</script>

<style scoped>
.parallax {
  /* The image used */
  background-image: url("~@/assets/images/pexels-craig-adderley-1557688.jpg");

  /* Set a specific height */
  min-height: 500px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>