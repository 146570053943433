<template>
    <div>
      <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\506q_Spezial.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4 ">
                                 Projekte - Speziallösungen
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
              <v-col>
                  <v-card-text>Spezielle Speziallösungen aufgrund von speziellen Bedingungen unter zur Hilfenahme spezieller Messtechnologien und Messanordnungen…. Ganz schön speziell! Neugierig gemacht auf Beispiele?</v-card-text>
              </v-col>
            </v-row>
            <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Projekt
                                        </th>
                                        <th class="text-left">
                                            Auftraggeber
                                        </th>
                                        <th class="text-left">
                                            Projektbeschreibung
                                        </th>
                                        <th class="text-left">
                                            Leistung
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, i) in componentData"
                                    :key="i"
                                    >
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.contractor }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.service }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referenceSpecialSolutions",
    data() {
        return {
            componentData: [
                 {
                "title": "D-Dorf HBF",
                "contractor": "Rheinbahn",
                "description": "Scan U-Bahnstation für Deckenverkleidung",
                "service": "2 Bahnsteige / 4 Gleise, ca. 100 Scan Standpunkte mit Trimble TX-5, 3D-Aufmaß der Decke für Neuplanung der Deckenverkleidung"
              },   {
                "title": "Wuppertal",
                "contractor": "Klostermann",
                "description": "Engestellenmessung neuer Signale in Vollsperrung",
                "service": "65.000 m, über 600 Engstellen, alle 2 Wochen Kontrollmessungen über 18 Monate"
              },   {
                "title": "Kokerei Prosper",
                "contractor": "Arcelor Mittal",
                "description": "Erneuerung Löschwagengleis, Gleiserneuerung Absetzer, FP-Feld, Batterie + Koksofen Türen einmessen, Suchschachtungen für Leirungen, Neubau Gasfackel, Rissmonintoring Gasbehälter",
                "service": "langfristiger Partner"
              },   {
                "title": "Köln-Dellbrück (S11)",
                "contractor": "DB Netz AG",
                "description": "BIM - LoD 300",
                "service": "2 komplette Haltepunkte + Umgebung als BIM Modell"
              },   {
                "title": "Scharnhorst",
                "contractor": "DB Netz AG",
                "description": "BIM - LoD 300",
                "service": "Kreuzungsbauwerk"
              },   {
                "title": "Siegen",
                "contractor": "DB Netz AG",
                "description": "Weichenhöhenpläne aus vorhandenen Gleisnetzdaten + zusätzliches Aufmaß",
                "service": "10 Weichen"
              },   
  {
    "title": "LGV Bretagne Pays de la Loire",
    "contractor": "Eiffage Rail",
    "description": "Hochgeschwindigkeitsstrecke LGV BPL von Le Mans nach Rennes",
    "service": "Erstellung eines FP-Feldes mit ca. 1600 Neupunkten, Erstellung eines GVP_Netzes mit ca. 8000 Gleisvermarkungspunkten, Nivellementleistungen von ca. 800 Kilometern, Gleisabsteckungen von ca. 230 Kilometern sowie 100 Weichen, Vormessleistungen von ca. 3.000 Kilometern, Gedo CE vorsys, Gedo CE, Festpunktfelder, Gleisabsteckung / Abnahme Gleisbau, Trassenplan"
  },
  {
    "title": "Nivellement Bergwerk Königsborn",
    "contractor": "RAG",
    "description": "Präzisionsnivellement im 3-monatigen Turnus",
    "service": "50.000 m Nivellement, Netzausgleichung für > 100 Punkte, Setzungsmessungen, Festpunktfelder"
  },
  {
    "title": "Massenermittlung mittels Drohneneinsatz",
    "contractor": "IBH GmbH",
    "description": "Geländeaufnahme zur Vorkalkulation mit unterschiedlichem Messequipment.\nVergleich der Genauigkeiten\nWirtschaftlichkeitsbetrachtung\nProjekt des Monats MAI/JUN 11",
    "service": "ca. 4.000 m Geländeaufnahme+ Massenermittlung, Mengenermittlung"
  },
]
        }
    }
}
</script>