<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-10 mx-md-7"> 
                <v-col>
                    <v-card-title class="pl-0 text-h5 text-lg-h2 ">
                        Karriere
                    </v-card-title>
                </v-col>
            </v-row>    
            <v-row>
                <v-col cols="12" md="8">
                    <v-card flat>
                        <v-img class="white--text align-end mx-md-8"
                                height="100%"
                                src="@/assets\images\601_Karriere_Innendienst2.png">
                           <v-overlay absolute :value="true" opacity="0.5" >
                                <v-row class="d-flex flex-column justify-center"> 
                                    <v-col class="text-h5 text-lg-h3 text-center">
                                            Innendienst
                                    </v-col>
                                </v-row>
                            </v-overlay>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col>
                    <p><strong> Für unser Büro in Schwelm suchen wir einen Vermessungsingenieur (w/m/d) im&nbsp;</strong><strong><span class="rot">Innendienst</span>.</strong></p>
                            <p><strong>Wir bieten</strong><br> - eine fachliche Einarbeitung in Ihren Bereich<br> - einen unbefristeten Arbeitsvertrag<br> - regelmäßige Weiterbildung<br> - ein modern ausgestattetes sowie gut belichtetes Büro<br> - viel Platz für eigene Ideen<br> - leckeren Kaffee und Softdrinks<br>- <span style="text-decoration: underline;"><em>30 Tage Urlaub</em></span></p>
                            <p><strong>Ihre Aufgaben</strong><br> - Ausarbeitung von Punktwolken<br> - Herstellung von 3D-Modellen,<br> - Bearbeitung von Maschinensteuerungsdaten<br> - Berechnung von komplexen Vermessungsnetzen in Lage und Höhe<br> - Auswertungen von Gleisabsteckungen<br> - Arbeitsvorbereitung von Projekten</p>
                            <p><strong>Ihr Profil</strong><br> 
                            <ul>
                                <li>abgeschlossenes Studium im Vermessungswesen oder einer verwandten Fachrichtung</li>
                                <li>Erfahrungen im Bereich der 3-D-Ausarbeitungen sind erwünscht aber nicht Bedingung</li>
                                <li>Fachkenntnissen und Erfahrungen in der Ingenieurvermessung</li>
                            </ul>
                            <br>Sie haben Spaß am Arbeiten in einem starken motivierten Team mit abwechslungsreichen Aufgaben, dann freuen wir uns auf Ihre Bewerbung:&nbsp;<br> 
                            <p>
                            <br><strong><span class="rot">info@ibh-vermessung.de</span></strong>&nbsp;</p>
                </v-col>
            </v-row>
            <v-row class="mt-16">
                    <v-col class="d-block d-sm-none" cols="12" md="8">
                        <v-card flat>
                            <v-img class="white--text align-end mx-md-8"
                                    height="100%"
                                    src="@/assets\images\602_Karriere_Aussendienst.png">
                            <v-overlay absolute :value="true" opacity="0.5" >
                                    <v-row class="d-flex flex-column justify-center"> 
                                        <v-col class="text-h5 text-lg-h3 text-center">
                                                Außendienst
                                        </v-col>
                                    </v-row>
                                </v-overlay>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col>
                        <div class="text_service">
                            <p><strong>Für unser Büro in Schwelm suchen wir einen Vermessungsingenieur (w/m/d) oder einen Vermessungstechniker (w/m/d) im</strong> <strong><span class="rot">Außendienst</span>.</strong></p>
                            <p><strong>Wir bieten</strong><br> - eine fachliche Einarbeitung in Ihren Bereich<br> - einen unbefristeten Arbeitsvertrag<br> - regelmäßige Weiterbildung<br> - moderne, innovative Messgeräte</p>
                            <p>- einen Dienstwagen, auch zur privaten Nutzung<br> - viel Platz für eigene Ideen<br>- <span style="text-decoration: underline;"><em>30 Tage Urlaub</em></span></p>
                            <p><strong>Ihre Aufgaben</strong><br> - Absteckung von Baumaßnahmen<br> - Messung von 3 D Vermessungsnetzen<br> - Bestandsaufnahmen mit Tachymetern, GNSS oder Laserscannern<br> - Durchführung und Auswertung von Gleisabsteckungen<br> - Arbeitsvor- und nachbereitung von Projekten</p>
                            <p><strong>Ihr Profil</strong><br>
                            <ul>
                                <li>abgeschlossenes Studium im Vermessungswesen oder eine Ausbildung zum Vermessungstechniker </li>
                                <li>Erfahrungen im Bereich der Ingenieurvermessung sind erwünscht aber nicht Bedingung</li>
                                <li>Deutsch auf muttersprachlichem Niveau</li>
                            </ul>
                            </p>
                            <p>Sie haben Spaß am Arbeiten in einem starken motivierten Team mit abwechslungsreichen Aufgaben, dann freuen wir uns auf Ihre Bewerbung:<br><br><a href="index.php?module=contact&amp;index[contact][action]=edit" target="_blank"><strong><span class="rot">info@ibh-vermessung.de</span></strong></a></p>
                        </div>
                    </v-col>
                    <v-col class="d-none d-sm-block" cols="12" md="8">
                        <v-card flat>
                            <v-img class="white--text align-end mx-md-8"
                                    height="100%"
                                    src="@/assets\images\602_Karriere_Aussendienst.png">
                            <v-overlay absolute :value="true" opacity="0.5" >
                                    <v-row class="d-flex flex-column justify-center"> 
                                        <v-col class="text-h5 text-lg-h3 text-center">
                                                Außendienst
                                        </v-col>
                                    </v-row>
                                </v-overlay>
                            </v-img>
                        </v-card>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Career',
    metaInfo: {
      title: 'Karriere',
    },
    components: {

    }
}
</script>

<style scoped>

</style>

<style lang="scss" scoped>

</style>