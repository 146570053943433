const monthProjects = [
    {
        id: 0,
        title: 'Kokerei Prosper',
        date: '04/2020',
        text:"Auswechslung des Löschwagengleises während des Betriebs. Hier fahren täglich 5.500t glühender Koks über das Gleis zum Löschturm. <br> <br>" + 
        "Abschnittsweise werden täglich 12m, bei einer Länge von 400m, von uns die Schienen millimetergenau eingerichtet. Zusätzlich scannen wir noch, den sonst schwer zugänglichen Bereich, mit der SX 10 für einen Werksbestandsplan. <br> <br>" +
        "Die Arbeiten finden flexibel kurzfristig tagsüber, nachts oder auch am Wochenende statt, je nachdem wann die Produktion das Gleis freigibt.",
        pathToPicture: ['04_2020.jpg'],
        shortText: "Gleisarbeiten in der Kokerei sind zwar auch Gleisarbeiten...aber doch auch für uns irgendwie anders. Aktuell wird in über 30 Schichten EIN Gleis im laufenden Betrieb gewechselt.",
    },
    {
        id: 1,
        title: 'Wuppertal Hbf',
        date: '02/2020',
        text:"Der Hauptbahnhof Wuppertal. <br> <br>" + 
        "5 Gleise / 5 ICE-Linien / 2 IC-Linien / 5 mal Regionalverkehr und 3 S-Bahn-Linien und bis auf die S68 halten alle Züge im Hbf Wuppertal. Hier ist was los, hier strömen Menschenmassen zwischen den Bahnsteigen. Aber nicht am ersten Februarwochenende 2020. Im Rahmen von Oberleitungsarbeiten haben wir unsere Vermessungsarbeiten zu Umbauten im Wuppertaler Hauptbahnhof durchgeführt. 2 Tage / 3 Messtrupps / >5.000 Meter Gleis / 15 Weichen und alles was bei der Trassierung von Gleisanlagen entscheidend ist! In einem konzentrierten und logistisch optimal abgestimmten Messeinsatz konnten wir die Arbeiten fertigstellen. Die Alternative: mindestens 4 Wochen in Nachtschichten auf unwegsamen Gelände mit schlechten Sichten. <br> <br>" +
        "Wir sind stolz auf diese Leistung!  ...und so langsam ist auch unser Personal wieder trocken - denn das Wetter war als einziger Punkt leider nicht auf unserer Seite! ;-) ",
        pathToPicture: ['02_2020.jpg'],
        shortText: "...und auf einmal war alles ganz still...",
    },
    {
        id: 2,
        title: 'Imagebroschüre',
        date: '01/2020',
        text:"<b>Wir reden nicht nur über Veränderungen, wir leben sie auch</b>.  <br> <br>" +

        "Von Gleisbau, Straßenbau und Ingenieurbau. <br>" + 
        
        "Von BIM und AVANI. <br>" +
        
        "Von DBREF und NXO-NET. <br>" +
        
        "Von Engstellen und Drohnenbefliegung. <br>"+
        
        "Von Maschinensteuerung bis DGM. <br> <br>"+
        
        "<b>Wir reden nicht nur über Veränderungen, wir zeigen sie auch.</b>",
        pathToPicture: ['01_2020.jpg'],
        shortText: "Die letztjährigen Veränderungen in unserer Firmenstruktur sollen nicht nur gelebt sondern auch gezeigt werden. Unsere neue Firmenpräsentation ist auf dem Markt und hier für Sie erhältlich. Lesen Sie was uns auszeichnet!",
    },
    {
        id: 3,
        title: 'Die IBH wird stärker!',
        date: '08/2019',
        text:"",
        pathToPicture: ['08.2019.jpg'],
        shortText: "Im Rahmen der strategischen Weiterentwicklung hat die SITECH Deutschland GmbH zum 01.08.2019 die Gesellschaftsanteile der Ingenieurbüro Herzbruch GmbH mit Sitz in Schwelm übernommen.",
    },
    // {
    //     id: 4,
    //     title: 'Trimble Dimension in Las Vegas',
    //     date: '11/2018',
    //     text:"Start",
    //     pathToPicture: "11.2018_LasVegas.jpg",
    //     shortText: "Abstract accepted. So hieß es im Juli 2018.",
    // },
    // {
    //     id: 5,
    //     title: 'Berlin Nordkreuz',
    //     date: '11/2018',
    //     text:"Start",
    //     pathToPicture: "11.2018_Berlin.jpg",
    //     shortText: "Haben Sie schon einmal bei einer Taktung von < 3 Minuten eine präzise Gleisvermessung durchgeführt?",
    // },
    // {
    //     id: 6,
    //     title: 'Dortmund - Hamm; Str. 2650',
    //     date: '10/2018',
    //     text:"Start",
    //     pathToPicture: "11_2018.jpg",
    //     shortText: 'Auf den Gleisen - über den Gleisen - neben den Gleisen". So beschreibt die DBAG selbst die Maßnahmen in diesem Abschnitt zwischen Dortmund und Hamm.',
    // },

  ]

export default monthProjects;