import Vue from "vue";

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)


export default class global extends Vue {
    // baseURL = "http://localhost/";
    baseURL = "it-herzbruch.de";

    sendRequest(message){
        let instance = axios.create({ baseURL: this.baseURL });

        instance.get('/projects/ibh/api/requests.php?function=SEND_Mail' +
            '&message=' + message.message +
            '&mail=' + message.mail +
            '&name=' + message.name +
            '&telephone=' + message.telephone)
            .then(response => {
                console.log(response);
        });
    }

    getBrochure(){
        let instance = axios.create({responseType: "blob" });

        // Local
        //instance.get('/projects/ibh/api/requests.php?function=downloadBrochure').then(response => {
        //  this.showFile(response.data); 
        // });

        instance.get('/api/requests.php?function=downloadBrochure').then(response => {
                this.showFile(response.data);

        });
    }

    showFile(blob){

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], {type: "application/pdf"})
      
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } 
      
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download="IBH.pdf";
        link.click();
        setTimeout(function(){
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }
}