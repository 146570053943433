<template>
    <div>
        <v-card flat class="mt-16 mx-md-10 mx-8"> 
            <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/machinecontrol" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\304q_Maschinensteuerung.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                  Maschinensteuerung
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <p>Grader und Bagger erledigen die Arbeit nicht von selber - noch nicht!
                            Aber durch unsere Umsetzung von digitalen Geländemodellen für Ihre Maschinensteuerungssysteme kommen wir da schon ganz nah dran!
                        </p>
                        <div class="columns clearfix margined">
                            <div class="text_service">
                                <p>Die Technik macht auch vor den Erd- und Planierarbeiten keinen Halt.</p>
                                <p>Zur Erstellung von Dämmen und Gräben, zum Profilieren von Böschungen, zur zentimetergenauen Herstellung von Planumsschutzschichten werden immer häufiger Maschinensteuerungssysteme auf den unterschiedlichsten Baumaschinen eingesetzt. Unter zur Hilfenahme von GPS oder auch Tachymetern lassen sich hiermit die Grader, die Bagger, die Raupen zentimetergenau durch das Baufeld steuern. <br><a href="index.php?module=services&amp;index[services][action]=details&amp;index[services][data][services_id]=28&amp;index[services][category]=6">Absteckarbeiten</a> werden minimiert, zusätzliches Personal zum Einrichten und Zuarbeiten der Arbeiten wird frei und kann anders eingesetzt werden.<br>Hochmoderne Neigungs- und Höhensteuerungssysteme für Erdbaumaschinen verlegen die Anzeige von Sollflächen, Schildneigungen und Kurvenbänder in das Führerhaus.</p>
                                <p>Wir sind mit unser langjährigen Erfahrung im Bereich der Maschinensteuerung <strong>Ihr</strong> Ansprechpartner wenn es darum geht, digitale Geländemodelle, 3-D-Modelle der Planung für Ihr spezielles System umzusetzen. Hierbei spielt es für uns keine Rolle ob es sich um einen Grader, eine Raupe oder einen Bagger handelt. <br>Ein Datenmodell für Trimble-Systeme, Wirth-Systeme und auch Topcon-Systeme wird von uns erstellt.<br>Synergieeffekte die sich bei uns dadurch ergeben, dass wir neben der Maschinensteuerung auch die <a href="index.php?module=services&amp;index[services][action]=details&amp;index[services][data][services_id]=27&amp;index[services][category]=6">Mengenermittlung</a>&nbsp;der Baumaßnahme betreuen können, kommen Ihrem Projekt wieder vollstens zu Gute.&nbsp;</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesMachineControl',
  components: {
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>