<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-10"> 
                <v-col>
                    <v-card  flat width="100%">
                        <v-card-title class=" px-0 text-h4 text-lg-h2 ">
                            Datenschutz
                        </v-card-title>
                    </v-card>
                   
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
<div>
    <p>Datenschutzerkl&auml;rung&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Wir freuen uns &uuml;ber Ihr Interesse an unserer Webseite&nbsp;ibh-vermessung.de&nbsp;(&ldquo;Webseite&ldquo;) und m&ouml;chten Ihren Besuch so angenehm wie m&ouml;glich gestalten. Betreiber dieser Webseite und Verantwortlicher f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten &uuml;ber diese Webseite ist die&nbsp;IBH Ingenieurb&uuml;ro Herzbruch GmbH, Mittelstra&szlig;e 36, 58332 Schwelm, 02336 40990,&nbsp;<a href="mailto:info@ibh-vermessung.de" rel="noreferrer noopener" target="_blank">info@ibh-vermessung.de</a>.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Neben einer komfortablen und effizienten Bedienbarkeit steht f&uuml;r uns der Schutz Ihrer personenbezogenen Daten im Vordergrund. Der Schutz Ihrer Privatsph&auml;re bei der Verarbeitung personenbezogener Daten ist uns ein wichtiges Anliegen, das wir in allen unseren Gesch&auml;ftsprozessen ber&uuml;cksichtigen. &nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Wir verarbeiten personenbezogene Daten, die beim Besuch unserer Webseite erhoben werden, daher stets gem&auml;&szlig; den jeweils einschl&auml;gigen datenschutzrechtlichen Bestimmungen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Mit dieser Datenschutzerkl&auml;rung informieren wir Sie dar&uuml;ber, welche personenbezogenen Daten von Ihnen erhoben und gespeichert werden, wenn Sie unsere Webseite besuchen bzw. unsere &uuml;ber die Webseite angebotenen Dienste nutzen. Au&szlig;erdem erhalten Sie Informationen dazu, wie und auf welcher Rechtsgrundlage Ihre Daten verwendet werden, welche Rechte Ihnen im Hinblick auf die Verwendung Ihrer Daten zustehen und welche Kontaktm&ouml;glichkeiten Ihnen zur Verf&uuml;gung stehen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <div>
        <ol start="1">
            <li>
                <p>Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Sie k&ouml;nnen unsere Webseite besuchen, ohne Angaben zu Ihrer Identit&auml;t preiszugeben. Beim Aufruf unserer Webseite werden jedoch durch den von Ihnen benutzten Browser automatisch Informationen an den Server unserer Webseite gesendet und tempor&auml;r in einem Log-File gespeichert. Ihre Identit&auml;t wird durch diese Informationen nicht preisgegeben.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten L&ouml;schung nach sechs Monaten gespeichert:&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ul>
            <li>
                <p>die IP-Adresse des anfragenden Rechners,&nbsp;</p>
            </li>
            <li>
                <p>das Datum und die Uhrzeit des Zugriffs,&nbsp;</p>
            </li>
            <li>
                <p>der Name und die URL der abgerufenen Datei,&nbsp;</p>
            </li>
            <li>
                <p>der von Ihnen verwendete Browser und ggfs. das Betriebssystem Ihres Rechners,&nbsp;</p>
            </li>
        </ul>
    </div>
</div>
<div>
    <div>
        <ul>
            <li>
                <p>Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt (Referrer),&nbsp;</p>
            </li>
            <li>
                <p>Webseiten, die vom System des Nutzers &uuml;ber unsere Webseite aufgerufen werden.&nbsp;</p>
            </li>
        </ul>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Die Erhebung und Verarbeitung dieser Daten erfolgt zu dem Zweck, die Nutzung unserer Webseite zu erm&ouml;glichen (Verbindungsaufbau). Diese Daten werden ausschlie&szlig;lich aus technischen Gr&uuml;nden gespeichert und werden zu keinem Zeitpunkt einer bestimmten Person zugeordnet. Au&szlig;erdem dient die Erhebung der genannten Daten dem Zweck, die Systemsicherheit und -stabilit&auml;t zu gew&auml;hrleisten, sowie zur technischen Administration der Netzinfrastruktur. Rechtsgrundlage ist insofern Art. 6 Abs. 1 lit. f DS-GVO. Unser berechtigtes Interesse an der Datenverarbeitung liegt dabei darin, das ordnungsgem&auml;&szlig;e Funktionieren unserer Webseite sowie der dar&uuml;ber abgewickelten Kommunikation sicherzustellen. R&uuml;ckschl&uuml;sse auf Sie als Person ziehen wir in diesem Zusammenhang nicht.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ol start="2">
            <li>
                <p>Cookies&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Wir setzen auf unserer Webseite&nbsp;keine&nbsp;Cookies ein.&nbsp;&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="3">
            <li>
                <p>Nutzung von&nbsp;OpenLayers&nbsp;</p>
            </li>
        </ol>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Um Ihnen unseren Standort anhand einer Karte darstellen zu k&ouml;nnen,&nbsp;nutzen wir&nbsp;die Programmier-bibliothek&nbsp;OpenLayers&nbsp;des&nbsp;OpenLayers&nbsp;Dev&nbsp;Team (<a href="http://openlayers.org/" rel="noreferrer noopener" target="_blank">http://openlayers.org</a>). Diese Bibliothek wird &uuml;ber die Serverinfrastruktur von&nbsp;OpenLayers&nbsp;bereitgestellt.&nbsp;Beim Aufruf unserer Website stellt Ihr Browser eine Verbindung zum Server&nbsp;von&nbsp;OpenLayers&nbsp;her um&nbsp;die Karte&nbsp;zu laden. Hierdurch werden Ihre IP-Adresse und technische Verbindungsdaten an den externen Server &uuml;bermittelt und mitgeteilt, welche Seite unserer Website Sie besucht haben.&nbsp;Dies ist notwendig um die Bereitstellung des Dienstes zu gew&auml;hrleisten. Aufgrund der Effizienz wird die Programmierbibliothek von Ihrer Browser-Software f&uuml;r eine bestimmte Zeit gespeichert (&quot;gecached&quot;). Dies bedeutet, dass Sie nicht immer eine &Uuml;bermittlung Ihrer IP-Adresse an die Server&nbsp;OpenLayers&nbsp;vornehmen.&nbsp;Rechtsgrundlage f&uuml;r die erforderliche Verarbeitung Ihrer IP-Adresse&nbsp;ist&nbsp;Art. 6 Abs. 1&nbsp;lit. f DS-GVO.&nbsp;Unser berechtigtes Interesse an der&nbsp;Datenverarbeitung liegt dabei darin, das ordnungsgem&auml;&szlig;e Funktionieren unserer Webseite&nbsp;sicherzustellen&nbsp;und Ihnen&nbsp;die Anfahrt zu unseren Gesch&auml;ftsr&auml;umen zu erleichtern.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="4">
            <li>
                <p>Datensicherheit&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <p>Alle von Ihnen pers&ouml;nlich &uuml;bermittelten Daten, einschlie&szlig;lich Ihrer Zahlungsdaten, werden mit dem allgemein &uuml;blichen und sicheren Standard SSL (Secure Socket Layer) &uuml;bertragen. SSL ist ein sicherer und erprobter Standard, der z.B. auch beim Onlinebanking Verwendung findet.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie erkennen eine sichere SSL-Verbindung unter anderem an dem angeh&auml;ngten s am http (also https://...) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Wir bedienen uns im &Uuml;brigen geeigneter technischer und organisatorischer Sicherheitsma&szlig;nahmen, um Ihre bei uns gespeicherten personenbezogenen Daten gegen Vernichtung, Verlust, Ver&auml;nderung oder vor einer unbefugten Offenlegung bzw. vor einem unbefugten Zugang zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.&nbsp;</p>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="5">
            <li>
                <p>Betroffenenrechte&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Als Betroffener im Sinne der DS-GVO stehen Ihnen die nachfolgend beschriebenen Rechte zu. Zur Geltendmachung dieser Rechte wenden Sie sich bitte wie folgt an uns:&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <p><a href="mailto:datenschutz@zeppelin.com" rel="noreferrer noopener" target="_blank">datenschutz@zeppelin.com</a> oder schriftlich an die oben genannte Adresse der&nbsp;IBH Ingenieurb&uuml;ro Herzbruch GmbH&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Auskunftsrecht&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie k&ouml;nnen gem. Art. 15 DS-GVO Auskunft &uuml;ber Ihre personenbezogenen Daten verlangen, die wir verarbeiten.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Recht auf Berichtigung&nbsp;&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, k&ouml;nnen Sie nach Art. 16 DS-GVO eine Berichtigung verlangen. Sollten Ihre Daten unvollst&auml;ndig sein, k&ouml;nnen Sie eine Vervollst&auml;ndigung verlangen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Recht auf Einschr&auml;nkung der Bearbeitung&nbsp;&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie haben nach Art. 18 DS-GVO das Recht, eine Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Recht auf L&ouml;schung&nbsp;&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie k&ouml;nnen nach Art. 17 DS-GVO die L&ouml;schung Ihrer personenbezogenen Daten verlangen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Recht auf Daten&uuml;bertragbarkeit&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Gem. Art. 20 DS-GVO haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Au&szlig;erdem haben Sie in den Grenzen des Art. 20 Abs. 1 DS-GVO das Recht diese Daten durch uns an einen von Ihnen benannten Dritten &uuml;bermitteln zu lassen.&nbsp;&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Widerspruchsrecht&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie k&ouml;nnen einer Verarbeitung von personenbezogenen Daten f&uuml;r Zwecke der Werbung einschlie&szlig;lich einer Analyse von Kundendaten f&uuml;r Werbezwecke jederzeit ohne Angabe von Gr&uuml;nden widersprechen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit einer Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1&nbsp;lit. e oder f DSGVO erfolgt, nach Ma&szlig;gabe des Art. 21 DS-GVO zu widersprechen. Wir werden&nbsp;die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Widerruf von Einwilligungen&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <div>
        <p>Au&szlig;erdem haben Sie jederzeit das Recht, von Ihnen erteilte datenschutzrechtliche Einwilligungserkl&auml;rungen mit Wirkung f&uuml;r die Zukunft zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="6">
            <li>
                <p>Automatisierte Einzelfallentscheidungen oder Ma&szlig;nahmen zum&nbsp;Profiling&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Wir nutzen keine automatisierten Verarbeitungsprozesse zur Herbeif&uuml;hrung einer Entscheidung &ndash; einschlie&szlig;lich&nbsp;Profiling.&nbsp;&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="7">
            <li>
                <p>Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen Datenschutzrecht verst&ouml;&szlig;t, haben Sie nach Ar. 77 Abs. 1 DS-GVO das Recht, sich bei einer Datenschutzaufsichtsbeh&ouml;rde eigener Wahl zu beschweren. Hierzu geh&ouml;rt auch die f&uuml;r den Verantwortlichen zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde:&nbsp;Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit NRW, Postfach 200444, 40102 D&uuml;sseldorf. F&uuml;r die E-Mail-Kommunikation mit der Aufsichtsbeh&ouml;rde k&ouml;nnen Sie folgende E-Mail-Adresse nutzen:&nbsp;<a href="mailto:poststelle@ldi.nrw.de" rel="noreferrer noopener" target="_blank">poststelle@ldi.nrw.de</a>.&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ol start="8">
            <li>
                <p>Dauer der Speicherung personenbezogener Daten/L&ouml;schung personenbezogener Daten&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Generell l&ouml;schen oder anonymisieren wir Ihre personenbezogenen Daten, sobald sie f&uuml;r die Zwecke, f&uuml;r die wir sie nach den vorstehenden Ziffern erhoben oder verwendet haben, nicht mehr erforderlich sind, es sei denn, die weitere Speicherung Ihrer personenbezogenen Daten ist aufgrund einer rechtlichen Verpflichtung erforderlich. N&auml;here Angaben zu den entsprechenden L&ouml;schfristen finden Sie jeweils bei der Beschreibung der einzelnen Datenverarbeitungen.&nbsp;&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <ol start="9">
            <li>
                <p>Weitergabe von Daten an Dritte / Empf&auml;nger der Daten&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Die von uns erhobenen und gespeicherten personenbezogenen Daten werden von uns in keinem Fall zum Verkauf, zum Handel oder zur Ausleihe genutzt. Ihre Daten geben wir nur an Dritte weiter, sofern wir hierzu gesetzlich verpflichtet sind, bzw. zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen, zur Aufkl&auml;rung einer rechtswidrigen Nutzung unserer Webseite oder Angebote oder f&uuml;r die Rechtsverfolgung (sofern konkrete Anhaltspunkte f&uuml;r ein gesetzeswidriges beziehungsweise missbr&auml;uchliches Verhalten vorliegen) erfolgen. Eine Weitergabe von Daten kann auch dann stattfinden, wenn dies der Durchsetzung von Vereinbarungen dient. Wir sind zudem gesetzlich verpflichtet, auf Anfrage bestimmten &ouml;ffentlichen Stellen Auskunft zu erteilen. Dies sind Strafverfolgungsbeh&ouml;rden, Beh&ouml;rden, die bu&szlig;geldbew&auml;hrte Ordnungswidrigkeiten verfolgen und die Finanzbeh&ouml;rden. Die Weitergabe dieser Daten erfolgt auf Grundlage unseres berechtigten Interesses an der Bek&auml;mpfung von Missbrauch, der Verfolgung von Straftaten und der Sicherung, Geltendmachung und Durchsetzung von Anspr&uuml;chen. Rechtsgrundlage ist Art.  6 Abs. 1 lit. f DS-GVO.&nbsp;&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>Wir sind f&uuml;r das Angebot unserer Produkte und Dienstleistungen auf vertraglich verbundene Fremdunternehmen und externe Dienstleister (&bdquo;Auftragsverarbeiter&ldquo;) angewiesen. In solchen F&auml;llen werden personenbezogene Daten an diese Auftragsverarbeiter weitergegeben, um diesen die weitere Bearbeitung zu erm&ouml;glichen. Diese Auftragsverarbeiter werden von uns sorgf&auml;ltig ausgew&auml;hlt und regelm&auml;&szlig;ig &uuml;berpr&uuml;ft, um sicherzugehen, dass Ihre Privatsph&auml;re gewahrt bleibt. Die Auftragsverarbeiter d&uuml;rfen die Daten ausschlie&szlig;lich zu den von uns vorgegebenen Zwecken verwenden und werden dar&uuml;ber hinaus von uns vertraglich verpflichtet, Ihre Daten ausschlie&szlig;lich gem&auml;&szlig; dieser Datenschutzerkl&auml;rung sowie den deutschen Datenschutzgesetzen zu behandeln.&nbsp;</p>
    </div>
</div>
<div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <ol start="10">
            <li>
                <p>Kontaktm&ouml;glichkeit / Datenschutzbeauftragter&nbsp;</p>
            </li>
        </ol>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
    <div>
        <p>F&uuml;r eine Auskunft &uuml;ber Ihre personenbezogenen Daten, zur Veranlassung einer Korrektur unrichtiger Daten oder deren Sperrung bzw. L&ouml;schung sowie f&uuml;r weitergehende Fragen &uuml;ber die Verwendung Ihrer&nbsp;personenbezogenen Daten steht Ihnen folgende Kontaktm&ouml;glichkeit &uuml;ber unseren Konzerndatenschutzbeauftragten offen:&nbsp;</p>
    </div>
    <div>
        <p>&nbsp;</p>
    </div>
</div>
<div>
    <p>Zeppelin GmbH&nbsp;<br>Konzerndatenschutzbeauftragter&nbsp;<br>Graf-Zeppelin-Platz 1&nbsp;<br>85748 Garching b. M&uuml;nchen&nbsp;<br>Tel: +49 89 32 000-0&nbsp;<br>Fax: +49 89 32 000-482&nbsp;<br>E-Mail:&nbsp;<a href="mailto:datenschutz@zeppelin.com" rel="noreferrer noopener" target="_blank">datenschutz@zeppelin.com</a> &nbsp;<br>&nbsp;</p>
</div>
<div>
    <p>Bitte beachten Sie, dass eine Auskunft ausschlie&szlig;lich dann erteilt werden kann, wenn Sie uns Ihren vollst&auml;ndigen Vor- und Nachnamen, Ihre aktuelle und ggf. auch alte Anschrift, Ihr Geburtsdatum und Ihre Emailanschrift angeben. Diese Angaben dienen ausschlie&szlig;lich zum Abgleich und stellen somit f&uuml;r Sie einen Schutz dar, dass nicht unberechtigte Dritte Ihre pers&ouml;nlichen Daten erhalten. W&uuml;nschenswert und hilfreich, aber nicht notwendig, sind dar&uuml;ber hinaus auch etwaige Angebots-, Vorgangs- und/oder Vertragsnummern, die wir Ihnen mitgeteilt haben, damit wir schneller die betreffenden Daten identifizieren k&ouml;nnen.&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>&nbsp;</p>
</div>
<div>
    <p>Stand: Juli 2022&nbsp;</p>
</div>
                    </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    "name": 'Datenschutz'
}
</script>