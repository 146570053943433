<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-10"> 
                <v-col>
                    <v-card  flat width="100%">
                        <v-card-title class=" px-0 text-h4 text-lg-h2 ">
                            Rechtliche Hinweise
                        </v-card-title>
                    </v-card>
                   
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>
                          <b>
                            Haftungsausschluss
                          </b>
                    </p>
                    <p>
                        Wir freuen uns über Ihr Interesse an unserer Internetseite www.ibh-vermessung.de (im Folgenden:
                        „Internetseite) und bitten Sie um Beachtung der nachfolgenden Hinweise.
                        Die auf der Internetseite der Ingenieurbüro Herzbruch GmbH (im Folgenden: IBH) angebotenen
                        Informationen und Dienste wurden von der IBH bereitgestellt und dienen allein Ihrer Information.
                        Die Inhalte der Internetseite wurden mit größter Sorgfalt zusammengestellt. Dennoch übernimmt die
                        IBH keine Gewährleistung für die Richtigkeit, Vollständigkeit und Aktualität der mitgeteilten
                        Informationen. Die IBH garantiert auch nicht den jederzeitigen störungsfreien Zugang zur Internetseite.
                        Die Haftung der IBH für Schäden, die sich aus einer Nutzung des Internetangebotes ergeben können,
                        ist ausgeschlossen; ausgenommen ist die Haftung für Schäden, die auf einer vorsätzlichen oder grob
                        fahrlässigen Handlung von IBH beruhen oder soweit es sich um Schäden an Leben, Körper oder
                        Gesundheit handelt.
                    </p>     
                    <p>           
                        Soweit auf Internetseiten Dritter (Links) verwiesen wird, wird für die Richtigkeit, Vollständigkeit und
                        Aktualität der dort mitgeteilten Informationen sowie für die Virenfreiheit der Seiten, auf die verwiesen
                        wird, keine Haftung übernommen. Die IBH haftet auch nicht, wenn Seiten, auf die verwiesen wird,
                        widerrechtlichen Inhalt haben sollten. Die IBH distanziert sich ausdrücklich von solchen
                        widerrechtlichen Inhalten.
                    </p>
                    <p>
                        <b>
                            Urheberrecht, Markenschutz
                        </b>
                    </p>
                    <p>
                        Die Inhalte dieser Internetseite unterliegen dem Urheberrecht. Sie dürfen ohne vorherige
                        ausdrückliche schriftliche Zustimmung der IBH weder als Ganzes noch in Teilen vervielfältigt,
                        verbreitet, verändert, kopiert, gespeichert oder Dritten zugänglich gemacht werden. Die auf dieser
                        Internetseite eingebundenen/verwendeten Bilder dürfen nicht ohne vorherige schriftliche Zustimmung
                        der IBH verwendet werden.
                        Einige auf dieser Internetseite wiedergegebenen Bilder und Grafiken unterliegen dem Urheberrecht
                        Dritter.
                        Die zum Download angebotenen Bilder und Datenblätter dürfen nicht zu widerrechtlichen Zwecken
                        und strafbaren Handlungen verwendet werden. Die auf der Internetseite verwendeten Marken und
                        Markenzeichen sind markenrechtlich geschützt.
                        </p>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    "name": 'Legal'
}
</script>