<template>
  <div>
    <v-container class="fullpage">
        <LandingPageBase :routes="routes"></LandingPageBase>
    </v-container>
    <WhoWeAre></WhoWeAre>
    <Parallax v-if="!isMobile()"></Parallax>
    <Portfolio></Portfolio>
    <ParallaxSecond v-if="!isMobile()"></ParallaxSecond>
    <AboutUs></AboutUs>
    <v-row class="pt-8">
      <v-col class="text-center">
        <v-icon v-on:click="topFunction" size="40px"> mdi-chevron-up </v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import LandingPageBase from '@/components/LandingPage/LandingPageBase'
import Portfolio from '@/components/LandingPage/Portfolio/Portfolio'
import WhoWeAre from '@/components/LandingPage/WhoWeAre/WhoWeAre'
import AboutUs from '@/components/LandingPage/AboutUs/AboutUs'
import Parallax from '@/components/LandingPage/Parallax/Parallax'
import ParallaxSecond from '@/components/LandingPage/Parallax/ParallaxSecond'

export default {
  name: 'LandingPageView',
  components: {
    LandingPageBase,
    Portfolio,
    WhoWeAre,
    AboutUs,
    Parallax,
    ParallaxSecond
  },
  metaInfo: {
      title: 'IBH - Ingenieurbüro Herzbruch GmbH',
       meta: [
          {name: 'description', content:  'keineswegs vermessen.'}
      ] 
    },
  computed : {
    siteHeight() {
      switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 100
            case 'sm': return 100
            case 'md': return 70
            case 'lg': return 70
            case 'xl': return 70
            default: return 70;
      }
    },
     width() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 400
            case 'sm': return 900
            case 'md': return 1200
            case 'lg': return 1400
            case 'xl': return 1400
            default: return 1400;
        }
      }
  },
  data () {
    return {
      inMove: false,
      activeSection: null,
      offsets: [],
      touchStartY: 0,
    }
  },
  props: ["routes"],
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|ipad|tablet|kindle|playbook|silk|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0; 
    },
    calculateSectionOffsets() {
        let sections = document.getElementsByTagName('section');
        let length = sections.length;
        for (let i = 0; i < length; i++) {
            let sectionOffset = sections[i].offsetTop;
            this.offsets.push(sectionOffset);
        }
    },
    scrollToSection(id, force = false) {
        if (this.inMove && !force) return false;
        this.activeSection = id;
        this.inMove = true;
        document.getElementsByTagName('section')[id].scrollIntoView({
            behavior: 'smooth'
        });
        setTimeout(() => {
            this.inMove = false;
        }, 400);
    },
    handleMouseWheel: function(e) {
        if (e.wheelDelta < 30 && !this.inMove) {
            this.moveUp();
        } else if (e.wheelDelta > 30 && !this.inMove) {
            this.moveDown();
        }
        e.preventDefault();
        return false;
    },
    moveDown() {
        this.inMove = true;
        this.activeSection--;
        // if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;
        this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
        this.inMove = true;
        this.activeSection++;
        // console.log(this.activeSection);
        // if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;
        // console.log(this.offsets);
        this.scrollToSection(this.activeSection, true);
      },
    touchStart(e) {
        e.preventDefault();
        this.touchStartY = e.touches[0].clientY;
    },
    touchMove(e) {
        if (this.inMove) return false;
        e.preventDefault();
        const currentY = e.touches[0].clientY;
        if (this.touchStartY < currentY) {
            this.moveDown();
        } else {
            this.moveUp();
        }
        this.touchStartY = 0;
        return false;
    }
  },
  created() {
    this.calculateSectionOffsets();
  //   window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
  //   window.addEventListener('mousewheel', this.handleMouseWheel, {
  //       passive: false
  //   }); // Other browsers
  //   window.addEventListener('touchstart', this.touchStart, {
  //       passive: false
  //   }); // mobile devices
  //   window.addEventListener('touchmove', this.touchMove, {
  //       passive: false
  //   }); // mobile devices
  // },
  // destroyed() {
  //   window.removeEventListener('mousewheel', this.handleMouseWheel, {
  //       passive: false
  //   }); // Other browsers
  //   window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
  //   window.removeEventListener('touchstart', this.touchStart); // mobile devices
  //   window.removeEventListener('touchmove', this.touchMove); // mobile devices
}
}
</script>
<style scoped>
  body {
    margin: 0;
    color: #FFF;
    overflow: hidden;
  }
  .fullpage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .hover:hover {
      color: var(--primary-color) !important;
      cursor: pointer;
      transition: 0.6s;
  }
</style>
