const projectArra =  [
    {
        title:"Vormessen für Stopfmaschinen",
        text:"Es ist neu und es revolutioniert den Gleisbau! Das Gleismesssystem Gedo CE sowie die Ausstattungsvariante vorsys. Als einer DER führenden Dienstleister in diesem Sektor zeigen wir Ihnen was mit dieser Technik alles möglich ist! >>> Messungen - Beratungen - Auswertungen - Schulungen <<< Alles was dieses Thema bietet, bringen wir Ihnen nahe. Kontaktieren Sie uns, und fragen nach Ihrem persönlichen Schulungstermin!",
        image:"401_Vormessen.jpg",
        href: "premeasure"
    },
    {
        title:"Gleisbau",
        text:"Schotter – Schwelle – zwei Schienen – 1435mm parallel. Hört sich einfach an. Das haben schon viele gedacht. Wir sind dabei es zu perfektionieren.",
        image:"402_Gleisbau.jpg",
        href: "trackconstruction"
    },
    {
        title:"Ingenieurbau",
        text:"Gibt es uninteressante Bauprojekte? Für uns nicht! Von Ein-Feld-Brücken bis hin zum Taktschiebeverfahren - jedes Projekt birgt seine Besonderheiten und auch seine Tücken. Wir helfen Ihnen sie zu meistern.",
        image:"403_Ingenieurbau.jpg",
        href: "engineering"
    },
    {
        title:"Maschinensteuerung",
        text:"Grader und Bagger erledigen die Arbeit nicht von selber - noch nicht! Aber durch unsere Umsetzung von digitalen Geländemodellen für Ihre Maschinensteuerungssysteme kommen wir da schon ganz nah dran!",
        image:"404_Maschinensteuerung.jpg",
        href: "machinecontrol"
    },
    {
        title:"Straßenbau",
        text:"Landstraße - Bundesstraße - Autobahn .... bei uns bleibt kein Fertiger stehen!",
        image:"405_Straßenbau.jpg",
        href: "roadconstruction"
    },
    {
        title:"Speziallösungen",
        text:"Spezielle Speziallösungen aufgrund von speziellen Bedingungen unter zur Hilfenahme spezieller Messtechnologien und Messanordnungen…. Ganz schön speziell!",
        image:"406_Spezial.jpg",
        href: "specialsolutions"
    }
]

export default projectArra;