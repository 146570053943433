<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-10"> 
                <v-col class="text-h4 text-lg-h2 ">
                    Downloads
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3" style="cursor: pointer" @click="download()">
                    <v-card>
                        <v-row class="px-3 text-subtitle-1">
                            <v-col cols="auto">
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-col>
                            <v-col cols="auto">
                                Broschüre:
                            </v-col>
                            <v-col cols="auto">
                                Ein kleiner Einblick.
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    "name": 'Downloads',
    methods: {
        download(){
            window.dispatchEvent(new CustomEvent("on-download-brochure"));
        }
    }
}
</script>