<template>
    <div>
        <v-card flat class="mt-16 mx-md-10 mx-8"> 
             <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/specialsolutions" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\306q_Speziallösungen.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                  Speziallösungen
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(data, i) in componentData"
                                :key="i">
                                <v-expansion-panel-header> 
                                         <v-card flat>
                                            <v-card-title v-html="data.title" class="text-body-1 text-md-h6 text-justify wrap-normal">
                                            </v-card-title>
                                            <v-card-text v-html="data.shortText">
                                            </v-card-text>
                                        </v-card>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col v-html="data.longText">
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>  
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesSpecialSolutions',
  components: {
  },
  data() {
      return {
        title: "Leistungen/Services - Speziallösungen",
        componentData: [
            {
                'title': 'Copter - UAV - Befliegungen',
                'shortText': 'ADLERAUGE IM EINSATZ ' +
                             'SIEHT ALLES - MISST ALLES, KOMMT ÜBERALL HIN! '+ 

                             'Schnell und präzise per Copter. ' +
                             'Jetzt im Einsatz bei der IBH!',
                'longText': 'Warum so reißerisch?<br>' +
                            'Weil es eine Technik ist, die jeder meint zu kennen und zu beherrschen. Aber die Bearbeitung eines Projektes mit dem Copter ist mit dem Erstellen von "Fotos von oben" noch lange nicht fertig. Im Gegenteil, dann fängt die Arbeit erst an.<br>' +
                            'Während der Copterflug, die Vorteile von Sicherungspersonal, doppelte Anfahrten, schwierige Zugängigkeiten minimiert, so bringt die anschließende Auswertung erst den wahren Mehrwert für den Kunden.<br><br>' +

                            'Massenberechnung; Bestandsaufnahme, 3-D-Darstellungen, Schüttgutermittlung...und vielleicht auch ihre Aufgabe. Fragen Sie uns, stellen Sie uns Ihr Projekt vor und wir denken zusammen mit Ihnen und suchen, die für Sie beste Lösung. Einfach mal "von oben".'
            },
            {
                'title': "Setzungsmessungen",
                'shortText': 'Unter Setzung versteht man die Bewegung eines Bauwerkes durch allmähliche Verdichtung des Untergrundes. Die Hauptursache ist hier in erster Linie das Gewicht des Bauwerkes.',
                'longText': 'Unter Setzung versteht man die Bewegung eines Bauwerkes durch allmähliche Verdichtung des Untergrundes. Die Hauptursache ist hier in erster Linie das Gewicht des Bauwerkes.<br><br>' +

                            'Die periodisch durchzuführenden Nivellements von Bauwerkspunkten gehören ebenso zu unserem Leistungspektrum wie natürlich auch die fachgerechte Auswertung. Eine Analyse der auftretenden Setzungen sowie das Berücksichtigen von außergewöhnlichen Setzungen in das eigentliche Messprogramm der Setzungsbeobachtungen runden dieses hochpräzise Fachgebiet ab.'
            },
            {
                'title': 'Schieflagenmessung/Minderwertermittlung',
                'shortText': 'Als Rahmenvertragspartner der RAG führen wir im nördlichen Ruhrgebiet Schieflagenmessungen durch. Es handelt sich hierbei um die nivellitische Erfassung der relativen Schiefstellung von Gebäuden durch den hier durchgeführten Bergbau.',
                'longText': 'Als Rahmenvertragspartner der RAG führen wir im nördlichen Ruhrgebiet Schieflagenmessungen durch. Es handelt sich hierbei um die nivellitische Erfassung der relativen Schiefstellung von Gebäuden durch den hier durchgeführten Bergbau. Der Vergleich von sich wiederholenden relativen Messungen, die sich stets auf den selben Bezugspunkt richten ergibt einerseits die aktuelle Schieflage des Gebäudes und andererseits die Veränderung zur vorherigen Messung.<br>'+
                            'In Zusammenarbeit mit der RAG ergibt sich hieraus das Minderwertermittlungsverfahren welches je nach ermittelter Schieflage zu geldlichen Entschädigungen der Hauseigentümer durch die RAG führen kann.'
            },
            {
                'title': "Inklinometermessungen",
                'shortText': 'Um Bewegungen im Baugrund festzustellen wird in das zu untersuchende Erdreich ein flexibles Rohr eingebaut, welches sich mit dem Boden verformen kann.',
                'longText': 'Um Bewegungen im Baugrund festzustellen wird in das zu untersuchende Erdreich ein flexibles Rohr eingebaut, welches sich mit dem Boden verformen kann. Man kann Vertikalinklinometer wie auch Horizontalinklinometer verwenden. Die Basislänge unseres Messgerätes beträgt 1m. Die Messung erfolgt immer mit Umschlagsmessung um systematische Fehlereinflüsse weitestgehend zu eliminieren.<br><br>' +

                            'Zusätzlich führen wir in der Regel an den Inklinometerstandpunkten noch Nivellements durch, um so auch mögliche Setzungen des Punktes zu erfassen.'
            },
            {
                'title': '3-D-Laserscanning',
                'shortText': 'Der Begriff Laserabtastung oder englisch Laserscanning bezeichnet die Abtastung von Oberflächen mittels Lasertechnologie. Beim 3D-Laserscanning werden die Konturen (Oberflächen) von Räumen mittels Impuls-, Phasenlaufzeit oder Lichtschnitttriangulation digital erfasst. Dabei entsteht eine diskrete Menge von dreidimensionalen Abtastpunkten, die als Punktwolke bezeichnet wird.',
                'longText': 'Der Begriff Laserabtastung oder englisch Laserscanning bezeichnet die Abtastung von Oberflächen mittels Lasertechnologie. Beim 3D-Laserscanning werden die Konturen (Oberflächen) von Räumen mittels Impuls-, Phasenlaufzeit oder Lichtschnitttriangulation digital erfasst. Dabei entsteht eine diskrete Menge von dreidimensionalen Abtastpunkten, die als Punktwolke bezeichnet wird. Die Koordinaten der gemessenen Punkte werden aus den Winkeln und der Entfernung in Bezug zum Ursprung (Gerätestandort) ermittelt. Anhand der Punktwolke werden entweder Einzelmaße wie z.B. Längen und Winkel bestimmt oder es wird aus ihr eine geschlossene Oberfläche aus Dreiecken konstruiert (Vermaschung) und z.B. in der 3D-Computergrafik zur Visualisierung verwendet.<br><br>' +

                            'Der Einsatz des Laserscanning findet zunehmend in der Architekturvermessung mit Schwerpunkten in der Bauforschung und Denkmalpflege Anwendung. Insbesondere verformte und beschädigte Bauwerke mit räumlich komplizierten Strukturen können schnell und vollständig erfasst werden. Moderne Lasermeßsysteme erreichen eine Punktgenauigkeit von bis zu 1 mm am Objekt. Ein Laserscanner, dessen Entfernungsbestimmung nach dem Impuls- oder Phasenmessverfahren funktioniert, speichert zusätzlich die Intensität des zurück reflektierten Laserlichtes ab. In Kombination mit einer Digitalkamera können die Punktwolken mit photorealistischen Texturen versehen werden.'
            },
            {
                'title': 'Monitoring',
                'shortText': 'Die systematische Erfassung von Bewegungen an Bauwerken, Gleisanlagen, Böschungen, Staudämmen erfordert häufig einen permanenten Beobachtungsfluss um jedweden Störfall zu vermeiden und die Sicherheit der Menschen in der Umgebung dieses zu beobachtenden Objektes zu gewährleisten.',
                'longText': 'Hinter diesem Begriff verbirgt sich die Möglichkeit Objekte wie Staudämme, Tunnelportale, Böschungen usw. permanent zu beobachten, die Daten digital zu erfassen und nahezu zeitgleich einer Auswertung zu unterziehen. Im Gegensatz zum tachymetrischen, rein beobachterbezogenem Messen wird beim Monitoring lediglich mindestens ein Tachymeter stationär im Gelände befestigt, der dann völlig automatisiert in einem vorgegebenen Messraster die signalisierten Objektpunkte erfasst.<br>'+
                            'Über Datenleitungen (Kabel, WLAN, 3G,...) werden diese Messwerte direkt an unser Rechenzentrum weitergegeben um somit just-in-time die Bewegungen des Objektes zu kontrollieren.<br>' +
                            'Die Einrichtung solcher Systeme, die Planung von Messstandorten sowie die Festlegung von Warnschwellen und Messwiederholungen erfordern Know-How und Erfahrung in diesem Fachbereich.<br>' +
                            'Wir arbeiten hier eng mit der Firma Vermessungsbedarf Sinning aus Wiesentheid zusammen, die sich hier seit über 15 Jahren einen Erfahrungsschatz aufgebaut hat, von dem die Auftraggeber nur profitieren können.<br>' +

                            'Die Minimierung des eingesetzten Personals wie auch der permanente Datenfluss, und somit die permanente Kontrolle von schwer bis unmöglich zugängigen Objekten macht dieses Messverfahren so attraktiv.<br>' +

                            'Es ist unsere neueste Technologie...<br>' +
                            '...und auch in diesem Fall ist unsere Weiterentwicklung Ihr Gewinn!'
            },
        ]
      }
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>