<template>
    <div>
        <v-card flat class="mt-16 mx-md-10 mx-8"> 
             <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/trackconstruction" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\302q_Gleisbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                  Gleisbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(data, i) in componentData"
                                :key="i">
                                <v-expansion-panel-header> 
                                         <v-card flat>
                                            <v-card-title v-html="data.title" class="text-body-1 text-md-h6 text-justify wrap-normal">
                                            </v-card-title>
                                            <v-card-text v-html="data.shortText">
                                            </v-card-text>
                                        </v-card>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col v-html="data.longText">
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesTrackConstruction',
  components: {
  },
  data() {
      return {
        title: "Leistungen/Services - Gleisbau",
        componentData: [
            {
                'title': 'NXO-NET: Die Ril-konforme Berechnung von Festpunktfeldern',
                'shortText': 'Seit Mitte 2017 ist es amtlich und für jeden unumgänglich: die Koordinatenberechnung von Festpunkten mit dem Programmsystem NXO-NET.<br>' +
                            'Die Einführung war ein großer Schritt von Seiten der DB NETZ AG - und eine große Herausforderung für die Ingenieurbüros, die für die DB tätig sind.<br>' + 
                            'Wir haben uns dieser Aufgabe gestellt...und können auch sagen: mit Erfolg!',
                'longText': 'NXO-NET: diese Online-Ausgleichungssoftware dient der standardisierten Auswertung und Qualitätssicherung von Erneuerungen und Verdichtungen im DB_REF_Referenznetz (PS0/PS1-Punkte).<br>' + 
                            'Es gibt keine Alternative mehr zu diesem Programm. Eine separate personifizierte Freischaltung für die Software ist erforderlich. Spezielle Importfilter, Berechnungsabläufe und Steuerparameter sind zu setzen und einzustellen. Trotz NXO-Net-Workshop vom Programmhersteller ist hier viel zusätzliche Zeit und individuelle Anpassungen im allgemeinen Mess- und Auswerteablauf nötig um einen wirtschaftlichen und fachgerechten Abaluf zu ermöglichen.<br>' +
                            'Die IBH als Rahmenvertragspartner der DB NETZ AG hat aktuell drei geschulte Mitarbeiter, die dieses Programm bedienen können und sich mit den zugehörigen Auswerte- und Dokumentationsabläufen auskennen.<br>' +  
                            'Leider wird bei Bauvorhaben zu selten darauf geachtet, ob zumindest die  Schlussdokumentation auch im DBREF16 ausführbar ist.<br>' + 
                            'Die Beschreibung "ist im DB_REF auszuführen" reicht hier seit letztem Jahr leider nicht mehr aus. Häufig sind umfangreiche Neubestimmungen im DBREF16 erforderlich um die Bestandsdokumentation auch Ril-konform abschließen zu können.<br><br>' +  

                            'Sprechen Sie uns an - wir prüfen das für Sie und geben Ihnen Hinweise zur Ausführung und zum Umfang der durchzuführenden Vermessungsarbeiten im Rahmen des NXO-Net-Projektes.',
            },
            {
                'title': 'Festpunktfelder',
                'shortText': 'Das Festpunktfeld ist unabhängig vom jeweiligen Bauprojekt das A und O für eine Richtlinien-konforme Bearbeitung des Projektes. Hier entscheidet sich bereits vor dem ersten Spatenstich ob die Einhaltung der Bautoleranzen überhaupt möglich ist!',
                'longText': 'Gleisbau - Ingenieurbau - Straßenbau<br>' +
                            'Das verantwortungsvolle Zusammenspiel von "So genau wie nötig" mit "So genau wie möglich" ist der Anspruch, dem wir uns jeden Tag stellen!<br>' + 
                            'Die Grundlage einer jeden Baumaßnahme bildet ein präzises Festpunktfeld. Je höher die Anforderungen an das Bauvorhaben, desto höher auch der Genauigkeitsanspruch an das jeweilige Festpunktfeld. Ob der Anschluss des DB-REF-System der DB AG per GPS erforderlich ist, oder das terrestrische Einmessen von Bodenpunkten zur Verdichtung des bestehenden Festpunktfeldes oder gar ein Höhennetz zum Anschluss an das DHHN 92 benötigt wird, sowohl von Know-How als auch vom Equipment sind wir hier bestens aufgestellt und können mit aktuellster Technologie höchste Präzision mit der Wirtschaftlichkeit der Baustelle kombinieren.<br>' +
                            'Die LV-Position "Übernahme Festpunktfeld" ist unserer Meinung nach die wichtigste und verantwortungsvollste Position im Bereich der Vermessung überhaupt!<br>',
            },
            {
                'title': 'Gleisabsteckung / Abnahme Gleisbau',
                'shortText': 'Die Absteckung von Gleistrassen ist schon speziell in seiner Art. Das Zusammenspiel von Baufirma und Vermessungsbüro ist hier von großer Bedeutung um eindeutiges Zahlenwerk in übersichtllicher Weise zu liefern!',
                'longText': 'Die Gleisabsteckung ist unser tägliches Geschäft. Indirekte Absteckungen der Hauptachsen und somit auch die Sicherung der zu bauenden Achse ist das Spezialgebiet in unserem Büro. Das Know-How unserer Ingenieure hilft Ihnen dabei, schon vor dem Bau mögliche Probleme im Bauablauf zu erkennen. Hinweise auf Bestandsanschlüsse, Engstellen oder Weichen die nicht umgebaut werden und somit in die eigentliche Absteckung eingerechnet werden müssen sind für uns kein Problem. Als Ergebnis erhalten Sie ein aussagekräftiges Absteckbuch in digitaler Form mit dem es für Sie ein leichtes sein wird das Gleis in seiner Solllage herzustellen.<br>' +

                            'Die Kombination mit unserer Vormesstechnologie vervollständigt das vermessungstechnische Gesamtpaket, und garantiert Ihnen den berühmten roten Faden im Bauablauf.'
            },
            {
                'title': 'Mengenermittlung',
                'shortText': 'Die scheinbar belanglosen Fragen bestimmen schon in der Anfangsphase eines Projektes die Richtung:<br>' + 

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"',
                'longText': '„Benötigen Sie die Abrechnung nach Querprofilen oder über ein DGM?<br>"' +

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"<br>' +

                            'Unsere Mengenermittlungsaufträge beginnen in der Regel mit einem qualifizierten Fachgespräch zwischen unserem Mitarbeiter und Ihrem zuständigen Bauleiter, Abrechner oder auch Bauüberwacher. Hier legen wir bereits im Vorfeld dar, wo die Unterschiede der Berechnung liegen, bzw. zeigen Ihnen anhand der Vorbemerkungen auf, welche Menegnermittlungsart gewünscht und letztendlich auch vom Auftraggeber akzeptiert wird.<br>'+

                            'Diese fachliche Unterstützung ist unser Service an Sie, die den weiteren Arbeitsprozess, sowie auch den Zeitraum der Mengenermittlung einer Baumaßnahme in einem überschaubaren Rahmen hält.<br>'+

                            'Und, dass Zeit Geld ist, brauchen wir Ihnen nicht mehr zu erzählen!',
            },
            {
                'title': 'Trassierung',
                'shortText': 'Das Berechnen von neuen Trassen, die Optimierung von bestehenden Trassen...unterschiedlichste Streckenprofile aber eine Richtlinie 800 und ein Büro als Ansprechpartner!',
                'longText': 'Das Aufmessen der örtlichen Gleislage zur späteren Einrechnung, wie auch die Einrechnung an sich nach RiL 800.0110/883.0030 gehört zu unseren Aufgaben. Handelt es sich um eine völlig neue Trasse oder ist ein bereits bestehender Streckenteil neu aufzumessen, ins DB-REF zu transformieren und gegebenenfalls eine Geschwindigkeitserhöhung anzubringen? Unterschiedlichste Anforderungen an eine Trassierung, unterschiedlichste Geometrieelemente aber eine Richtlinie und ein Büro als Ansprechpartner.'
            },
            {
                'title': 'Weichenhöhenplan',
                'shortText': 'Weichen - hier trennt sich nicht nur das Zweiggleis vom Stammgleis sondern auch die Spreu vom Weizen...',
                'longText': 'Liegen Bogenweichen in durchgehenden Hauptgleisen in Überhöhung vor, so ist ein Weichenhöhenplan zu erstellen. Da allerdings die durchgehenden Schwellen einer Weiche ein starres Gebilde darstellen, wird der Höhenverlauf der einzelnen Schienenstränge des Zweiggleises von denen des Stammgleises abweichen. Eine überhöhte Weiche stellt geometrisch betrachtet ein Kegelmantel dar. Das bedeutet, dass bei einer im Rechtsbogen befindlichen Weichenverbindung die außen liegende Weiche höher liegt als die innere Weiche. Aufgrund dieser Tatsache sind die Gradienten für beide Gleise zu berechnen und in einem Weichenhöhenplan darzustellen. Der Bezug zur Kilometrierung der Strecke wird hier in einem Kilometrierungsband zusätzlich verdeutlicht.<br><br>' + 

                            'Sowohl die Einrechnung von überhöhten Weichen und Weichenverbindungen als auch die Erstellung eines Weichenhöhenplanes nach RiL 883.0030 befindet sich in unserem Arbeitsspektrum.'
            },
            {
                'title': 'IVL-Pläne',
                'shortText': 'Das Ergebnis der AVANI Einarbeitungen. Ein Planwerk der DB AG über das deuschlandweite Streckennetz.',
                'longText': 'Der IvL-Plan dokumentiert neben der eigentlichen Gleisgeometrie noch den DB-Grundbesitzstand. Hieraus lassen sich Schachtinformationen, Kabelkanallängen, Böschungsverläufe, Bahnübergänge, etc. entnehmen. Das Zusammenspiel von IvL-Plan und Bahn-, Geodaten vollzieht sich im System AVANI. Dies ist die Datenbank der DB AG in der sich alle Informationen der jeweiligen Gleisstrecke befinden. Sie wird ständig aktuell gehalten und bei Neu- und Umbauprojekten aktualisiert. So ist für die DB AG jederzeit ein aktueller Plan für den jeweiligen Streckenabschnitt einsehbar.<br><br>' + 

                            'Die Informationen hierzu stammen von Geländeaufnahmen, sowie auch von Entwurfsvermessungen oder Feldvergleichen. Die weitere Aufbereitung dieser Daten zum Einpflegen in den AVANI-Datenbestand ist ein wichtiger Zweig in unserem Portfolio.'
            },
            {
                'title': 'Trassenplan',
                'shortText': 'Trassenplan = Gleisvermarkungsplan = IvmG Plan = Trassenplan = Gleisvermarkungsplan<br>' + 
                             '...und doch meinen alle das selbe: die Grundlage aller Instandhaltungsarbeiten der DB AG',
                'longText': 'Der Trassenplan bildet die Grundlage aller Instandhaltungsarbeiten der DB AG. Er dokumentiert alle relevanten Informationen zur Laufendhaltung einer optimalen Gleisgeometrie. Die Darstellung der Vermarkung in Relation zur Gleisgeometrie sowohl in der Lage als auch in der Höhe wird mittels Pfeilhöhen millimetergenau festgelegt.<br><br>' + 

                            'Als Ergebnis der Entwurfsvermessung erstellen wir Trassenpläne nach den Richtlinien der DB AG. Aber auch für Sondervorschläge sind wir offen. In Absprache mit den jeweiligen Betreibern sind auch trassenplanähnliche Darstellungen möglich.<br><br>' + 

                            'Am Ende einer solchen Bearbeitung steht der Nutzen dieser Dokumentation für den Streckenbetreiber. Mit Hilfe dieser Pläne ist die Instandhaltung von Gleisanlagen zu jedem Zeitpunkt möglich. Vormessarbeiten zur Durchführung von Stopfarbeiten basieren auf diesen Trassenplänen und bilden somit die Grundlage für nahezu jeden Stopfgang.'
            },
            {
                'title': 'Feste Fahrbahn',
                'shortText': 'ICE-Trasse Köln - Rhein/Main<br>' +
                              'ICE-Trasse Nürnberg-Ingolstadt<br>' +
                              'ICE-Trasse Erfurt - Halle<br>' +
                              'hier merkt der Fahrgast auch bei 250 km/h noch nicht die Bewegung in der Kaffeetasse!',
                'longText': 'Im Gegensatz zum klassischen Oberbau der Eisenbahn, dem Schotteroberbau, liegen bei der Bauart der Festen Fahrbahn die Schwellen in einer festen, biegesteifen Tragschicht aus Beton oder Asphalt. Sie sind unverrückbar fixiert. Dieser wartungsarme Oberbau wird insbesondere bei Schnellfahrstrecken mit Geschwindigkeiten > 200km/h eingebaut.<br><br>' +

                            'Diese Art der Bauausführung erfordert insbesondere bei der Vermessung höchste Ansprüche an die Genauigkeit. Bei geforderten Richtungsgenauigkeiten von <2mm auf 5m und Längshöhenabweichungen von < 2mm ist hier geschultes Fachpersonal und genaueste Messtechnologie die einzige Möglichkeit diesen Ansprüchen gerecht zu werden. Unsere Gerätschaften finden Sie hier. Fragen Sie uns zusätzlich nach unseren Experten auf diesem Gebiet. Und es macht keinen Unterschied mehr ob es sich um die Bauart Rheda oder Züblin handelt.'
            },
            {
                'title': 'Entwurfsvermessung',
                'shortText': '...und am Anfang stand der Tachymeter...<br>' +
                             '   Solange keiner genau weiss, was sich draußen befindet, kann auch drinnen keiner planen was draußen hin soll!<br>'  +
                             'Die Entwurfsvermessung!',
                'longText': 'Die Entwurfsvermessung behandelt das Aufmessen von Fahrbahnen und Bahnanlagen sowie der Zwangspunkte unter Berücksichtigung der Genauigkeiten der DS 883.0025. Diese Daten werden von uns weiterverarbeitet und für die gleisgeometrische Bearbeitung der Trassen und Gradienten nach RiL 883.0030 berechnet und dokumentiert. Die Darstellung als Soll-Ist-Vergleich auf Grundlage der Gleisgeometrie gehört ebenso zu unserer Tätigkeit, wie auch die Erstellung von Weichenhöhenplänen.'
            },
            {
                'title': 'Bahn - Geodaten',
                'shortText': 'AVANI - Das Informationssystem der Deutschen Bahn AG',
                'longText': 'Die Deutsche Bahn hat für den Betrieb und die Instandhaltung ihrer Strecken ein geographisches Informationssystem eingerichtet, das AVANI. Es wird weiterhin auch genutzt für den Ausbau der vorhandenen Anlagen und für die Planung und den Bau neuer Strecken. Dieses Informationssystem stellt den aktuellen Nachweis der ortsfesten Anlagen dar. Bisher führte jeder Fachbereich eigene Nachweise die mehr oder weniger als Insellösungen zu betrachten sind. 1996 hat die DB AG begonnen das Vorgängersystem DB-GIS aufzubauen. Seitdem sind nahezu alle Beauftragungen von Seiten der DB AG mit der Position versehen, dass alle Änderungen gegenüber dem Altbestand zu erfassen sind und unter Verwendung der entsprechenden Fachschalen in den Datenbestand des früher DB-GIS, heute AVANI einzupflegen sind.<br><br>' + 

                            'Die AVANI-konforme Aufnahme, wie auch das Erstellen von Datenerfassungsblättern für die einzelnen Fachdienste wird durch uns ausgeführt.'
            },
            ]
      }
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>
