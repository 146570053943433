const routeArray = [
    {
        path: '/',
        name: 'LandingPage',
        displayName:"Start",
        hasSubRoutes: 0,
    },
    {
        path: '/services',
        name: 'Service',
        displayName:'Leistungen',
        hasSubRoutes: 0,
        children: [
            {
            path: '/premeasure',
            displayName:"Vormessen",
            },
            {
            path: '/trackconstruction',
            displayName:"Gleisbau",
            },
            {
            path: '/engineering',
            displayName:"Ingenieurbau",
            },
            {
            path: '/machinecontrol',
            displayName:"Maschinensteuerung",
            },
            {
            path: '/roadconstruction',
            displayName:"Straßenbau",
            },
            {
            path: '/specialsolutions',
            displayName:"Speziallösungen"
            }
        ]
    },
    {
        path: '/projects',
        displayName:'Projekte',
        hasSubRoutes: 0,
        children: [
            {
            path: '/premeasure',
            displayName:"Vormessen",
            },
            {
            displayName: "Gleisbau",
            path: '/trackconstruction',
            },
            {
            path: '/engineering',
            displayName:"Ingenieurbau",
            },
            {
            displayName: "Maschinensteuerung",
            path: '/machinecontrol',
            },
            {
            path: '/roadconstruction',
            displayName:"Straßenbau",
            },
            {
            path: '/specialsolutions',
            displayName:"Speziallösung",
            },
            
        ]
    },
    {
        path: '/news',
        displayName:'News',
        hasSubRoutes: 0,
    },
    {
        path: '/aboutus',
        displayName:'Über uns',
        hasSubRoutes: 0,
    },
    {
        path: '/career',
        displayName:'Karriere',
        hasSubRoutes: 0,
    },
    
  ]

export default routeArray;