<template>
    <div>
        <v-card flat class="mx-10 mt-16 ma-md-16"> 
            <v-row class=""> 
                <v-col>
                    <v-card-title class="text-h5 text-lg-h2 ">
                        Leistungen
                    </v-card-title>
                </v-col>
            </v-row>
            <v-card-text>
                <v-row>
                    <v-col class="text-body-2 text-sm-subtitle-1 text-justify">
                        Ihre Vorhaben für den Gleisbau sind Herausforderung und Ansporn für Höchstleistungen, die wir gerne und bekanntermaßen leisten. Präzise, von Qualität bestimmte Arbeit ist nicht nur unser Auftrag - sondern bei der IBH eine Angelegenheit, die sehr viel mit Leidenschaft zu tun hat. Die schönste Tradition ist für uns, aus Innovation und Erfahrung eine möglichst große Schnittmenge zu erzeugen.
                    </v-col>
                </v-row>
                <v-row class="pt-7 pt-md-0">
                    <v-col lg="4" v-for="(service, i) in services" :key="i">
                        <single-service  style="max-height: 500px" :service="service"></single-service>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import serviceArray from '../../configs/services';
import singleService from '@/components/LandingPage/Portfolio/singleService'

export default {
  name: 'ServiceView',
  components: {
      singleService,
  },
  metaInfo: {
      title: 'Leistungen',
  },
  data() {
      return {
        title: "Leistungen",
        services: serviceArray
      }
  },
}
</script>

<style scoped>
    .data:hover {
        cursor: pointer;
        background: lightgray;
        transition: 0.6s;
    }
</style>
