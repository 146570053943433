<template>
        <v-col class="d-flex text" >
            <v-row>
                <NavItem v-for="route in routes" :key="route.displayName" class="px-1" v-bind:route="route"/>
            </v-row>
        </v-col>
</template>

<script>
import NavItem from '@/components/AppBar/NavItem.vue'
export default {
    name:"NavBar",  
    components: {
        NavItem
    },
    data() {
        return {
        }
    },
    props:{
        routes: Array
    },
}
  
</script>

<style>
</style>