<template>
    <div class="justify-center ">
        <v-card-text class="d-flex justify-center pb-0">Wir sind die</v-card-text>
        <v-card-title class="d-flex justify-center pt-1 text-h6 text-lg-h3"> IBH - Ingenieurbüro Herzbruch GmbH  </v-card-title>
        <v-card-text class="d-flex justify-center text-h6 text-lg-h4 mt-2 mt-md-10 pb-0" >Ihr Ansprechpartner für </v-card-text>
        <v-card-text class="d-flex justify-center text-h6 text-lg-h4 ">
            <carousel :per-page="1" autoplay loop v-bind:paginationEnabled="false" style="width: 350px">
                            <slide
                            v-for="(service, i) in services"
                            :key="i"
                            class="pl-lg-2 ">
                                <span style="display: flex; justify-content: center">{{service}}</span>
                            </slide>
            </carousel>
        </v-card-text>
        <v-card-text class="mt-2 mt-md-6 mb-6 d-flex justify-center text-subtitle text-lg-h5 text-center">Fordern Sie unser Ingenieurdenken heraus. Es ist Ihr Gewinn.</v-card-text>
    </div>
</template>

<script>
export default {
    name:"DescBox",
    data() {
        return {
            services: ["Gleisvermessungen", "Absteckungen", "Abnahmen", "Ingenieurbau", "Brückenbau", "Straßenbau", "Gleisvermarkung", "IvL-Plan", "Ausgleichsgradiente", "Netzausgleichungen"]
        }
    }
}
</script>

<style scoped>
    .secondary-color {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
    }
    .descr {
        line-height: 4em;
    }
</style>