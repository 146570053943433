<template>
    <div>
        <v-card flat class="mt-16 mx-md-10 mx-8"> 
             <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/engineering" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\303q_Ingenieurbau.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4">
                                  Ingenieurbau
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(data, i) in componentData"
                                :key="i">
                                <v-expansion-panel-header> 
                                         <v-card flat>
                                            <v-card-title v-html="data.title" class="text-body-1 text-md-h6 text-justify wrap-normal">
                                            </v-card-title>
                                            <v-card-text v-html="data.shortText">
                                            </v-card-text>
                                        </v-card>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col v-html="data.longText">
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesEngineering',
  components: {
  },
  data() {
      return {
        title: "Leistungen/Services - Ingenieurbau",
        componentData: [
            {
                'title': 'Festpunktfelder',
                'shortText': 'Das Festpunktfeld ist unabhängig vom jeweiligen Bauprojekt das A und O für eine Richtlinien-konforme Bearbeitung des Projektes. Hier entscheidet sich bereits vor dem ersten Spatenstich ob die Einhaltung der Bautoleranzen überhaupt möglich ist!',
                'longText': 'Gleisbau - Ingenieurbau - Straßenbau<br><br>' +


                            'Das verantwortungsvolle Zusammenspiel von "So genau wie nötig" mit "So genau wie möglich" ist der Anspruch, dem wir uns jeden Tag stellen!<br><br>' + 

                            'Die Grundlage einer jeden Baumaßnahme bildet ein präzises Festpunktfeld. Je höher die Anforderungen an das Bauvorhaben, desto höher auch der Genauigkeitsanspruch an das jeweilige Festpunktfeld. Ob der Anschluss des DB-REF-System der DB AG per GPS erforderlich ist, oder das terrestrische Einmessen von Bodenpunkten zur Verdichtung des bestehenden Festpunktfeldes oder gar ein Höhennetz zum Anschluss an das DHHN 92 benötigt wird, sowohl von Know-How als auch vom Equipment sind wir hier bestens aufgestellt und können mit aktuellster Technologie höchste Präzision mit der Wirtschaftlichkeit der Baustelle kombinieren.<br><br>' +

                            'Die LV-Position "Übernahme Festpunktfeld" ist unserer Meinung nach die wichtigste und verantwortungsvollste Position im Bereich der Vermessung überhaupt!<br><br>',
            },
             {
                'title': 'Mengenermittlung',
                'shortText': 'Die scheinbar belanglosen Fragen bestimmen schon in der Anfangsphase eines Projektes die Richtung:<br><br>' + 

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"',
                'longText': '„Benötigen Sie die Abrechnung nach Querprofilen oder über ein DGM?<br><br>"' +

                            '„Erfolgt die Abrechnung nach IST oder nach SOLL?"<br><br>' +

                            'Unsere Mengenermittlungsaufträge beginnen in der Regel mit einem qualifizierten Fachgespräch zwischen unserem Mitarbeiter und Ihrem zuständigen Bauleiter, Abrechner oder auch Bauüberwacher. Hier legen wir bereits im Vorfeld dar, wo die Unterschiede der Berechnung liegen, bzw. zeigen Ihnen anhand der Vorbemerkungen auf, welche Menegnermittlungsart gewünscht und letztendlich auch vom Auftraggeber akzeptiert wird.<br><br>'+

                            'Diese fachliche Unterstützung ist unser Service an Sie, die den weiteren Arbeitsprozess, sowie auch den Zeitraum der Mengenermittlung einer Baumaßnahme in einem überschaubaren Rahmen hält.<br><br>'+

                            'Und, dass Zeit Geld ist, brauchen wir Ihnen nicht mehr zu erzählen!',
            },
            {
                'title': "Ausgleichsgradiente",
                'shortText': 'Die Ausgleichsgradiente beschreibt nach ZTV-ING die bestmögliche Angleichung der Rohbauistgradiente an die planmäßig vorgegebene Sollgradiente.<br>' +
                             'Die Berechnung eine Kunst - das Ergebnis ein Gewinn für Sie!',
                'longText': 'Die Ausgleichsgradiente beschreibt nach ZTV-ING die bestmögliche Angleichung der Rohbauistgradiente an die planmäßig vorgegebene Sollgradiente.<br><br>' +

                            'Mit Hilfe der Software Bridge-T berechnen wir die Ausgleichsgradiente unter der Berücksichtigung der ZTV-ING 3.4. Die Beachtung der örtlichen Besonderheiten des Bauwerks, das Ziel einer Minimierung des Gussasphaltes innerhalb der erlaubten Toleranzen sowie ein ständiges Auge sowohl auf den statischen als auch auf den fahrdynamischen Aspekten haben, all das kann von unseren Mitarbeitern garantiert werden.<br><br>' +

                            'Das Programm lebt aus unseren Erfahrungen mit Ihren Projekten und wird stetig weiterentwickelt und den Bedürfnissen der Baufirma einerseits sowie dem Auftraggeber andererseits angepasst.'
            },
            {
                'title': "Tunnelbau",
                'shortText': 'Ein Pessimist sieht das dunkle eines Tunnels.<br>'+
                            'Ein Optimist sieht das Licht am Ende des Tunnels.<br>' +
                            'Ein Realist sieht den Zug im Tunnel.',
                'longText': 'Der Tunnelbau ist ein sehr spezieller Teil unseres Vermessungsportfolios.<br><br>' +

                            'Man unterscheidet hier zwischen der offenen und der geschlossenen (bergmännischen) Bauweise.<br><br>' +

                            'Die offene Bauweise von Tunnelbauten ist für uns ein ingenieurtechnisches anspruchsvolles Verfahren. Hier bringen wir immer wieder unser Know-How, unsere Begeisterung für ein Projekt mit ein.<br>' +
                            'In Kombination mit dem Erstellen von präzisen Festpunktfeldern für solche Projekte, sowie auch den sehr wichtigen Setzungs- und Deformationsmessungen können wir hier die ganze Bandbreite unseres Ingenieurwissens für Sie mit einbringen.<br>' +

                            'Der Tunnelvortrieb in geschlossener Bauweise erfordert sehr spezielle Kenntnisse und Gerätschaften. Diese zumeist sehr teure Hardware macht für unser Büro aufgrund der doch nur sehr vereinzelt auftretenden Tunnelprojekte keinen Sinn.'
            },
            {
                'title': "Absteckung / Abnahme im Ingenieurbau",
                'shortText': 'Ein Ingenieurbüro im Ingenieurbau... bleiben da noch Fragen offen?',
                'longText': 'Die Nutzung der neuesten Technologien in der Vermessungsabteilung garantiert Ihnen auch im Ingenieurbau einen zügigen Bauablauf. Die Genauigkeit als der entscheidende Faktor garantiert Ihnen, dass unterschiedlichste Bauabläufe und auch unterschiedlichste Arbeitsweisen dennoch zu EINEM Ergebnis führen:<br><br>' + 

                            'Die Umsetzung Ihres Projektes zur Zufriedenheit des Bauherrn!'
            }
        ]
      }
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>