<template>
    <div>
        <v-card flat class="mt-16 mx-10"> 
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\504q_Maschinensteuerung.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-subtitle-1 text-md-h5 text-lg-h4 ">
                                   Projekte - Maschinensteuerung
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>Wir bereiten Planungsunterlagen zu digitalen Geländemodellen nach Kundenwünschen auf, so dass sie mit der Feldrechnersoftware oder von Maschinensteuerungsystemen eingelesen und verarbeitet werden können.
                        <br>
                        <br>
                        - Aushub: Baugruben, Fundamente, Bodenplatten, Außenanlagen etc.
                        <br>
                        - Straßenbau: Verkehrsflächen, Straßenbauschichten
                        <br>
                        - Dammbau, Kanalbau, Renaturisierung von Gewässern
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Projekt
                                    </th>
                                    <th class="text-left">
                                        Auftraggeber
                                    </th>
                                    <th class="text-left">
                                        Projektbeschreibung
                                    </th>
                                    <th class="text-left">
                                        Leistung
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item, i) in componentData"
                                :key="i"
                                >
                                    <td>{{ item.project }}</td>
                                    <td>{{ item.contractor }}</td>
                                    <td v-html="item.description"></td>
                                    <td v-html="item.service"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name:"referencesMachineControl",
    data() {
        return {
            componentData: [
               {
                "project": "BV Duisburg- KV Terminal Logport VI",
                "contractor": "Fonteyne Tief- und Straßenbau GmbH",
                "description": "Neubau eines Terminals mit ca. 500 m Zufahrtstraße",
                "service": "Grundlagenmessung, Festpunktfelderstellung und Verdichtung, Einmessung der Bestandsanschlußstellen, Erstellung von Ausühhrungsplänen und Daten, DGM fürr GPS-Rover, Bagger, Raupe, Grader und Fertiger, Absteckdaten fürr Tachymeter, Absteckarbeiten, Feinabsteckung von Bauwerken (Brückenbauwerke, Kranbahnbalken, Rückhaltebecken usw.), Feinabsteckung für den Straßenbau wie Bord-, Rand- und Betoneinfassungen, Feinabsteckung fürr den Kanalbau wie Straßenabläufe, Einmessarbeiten der Neubauten in Lage und Höhe, Herstellung der Bestandspläne und Dokumentation"
              },
               {
                "project": "BV Duisburg Umgehung Meiderich",
                "contractor": "Fonteyne Tief- und Straßenbau GmbH",
                "description": "ca. 900 m Straßen- und Kreisverkehrneubau",
                "service": "Grundlagenmessung, Festpunktfelderstellung und Verdichtung, Einmessung der Bestandsanschlußstellen, Erstellung von Ausühhrungsplänen und Daten, DGM fürr GPS-Rover, Bagger, Raupe, Grader und Fertiger, Absteckdaten fürr Tachymeter, Absteckarbeiten, Feinabsteckung für den Straßenbau wie Bord-, Rand- und Betoneinfassungen, - Feinabsteckung fürr den Kanalbau wie Straßenabläufe, Einmessarbeiten der Neubauten in Lage und Höhe, Herstellung der Bestandspläne und Dokumentation"
               },
                {
                "project": "BV Kuhschnappel",
                "contractor": "Arning Bauunternehmung GmbH",
                "description": "Errichtung von Windenergieanlagen",
                "service": " Erstellung von Ausführungsdaten, DGM für die Baugruben, Tiefteile, Aufschüttungen, Fahrwege, Stell- und Montageflächen, Kranausleger und Lagerflächen erstellt"
               },
               {
                "project": "BV Kuhschnappel",
                "contractor": "Arning Bauunternehmung GmbH",
                "description": "Errichtung von Windenergieanlagen",
                "service": " Erstellung von Ausführungsdaten, DGM für die Baugruben, Tiefteile, Aufschüttungen, Fahrwege, Stell- und Montageflächen, Kranausleger und Lagerflächen erstellt"
               },
                {
                "project": "BV Bergkamen Berliner Straße",
                "contractor": "H & W Tiefbau GmbH & Co. KG",
                "description": "Neubau Wohngebiet",
                "service": "Erstellung von Ausführungsdaten, DGM für das Fahrbahn OK Fertig, Kanalachsen mit Hausanschlüssen, Schächte und Straßenabläufe und Häuserbaugruben"
               },
                {
                "project": " BV Neumünster Neuenbrook 1",
                "contractor": "Ernst Krebs GmbH & Co. KG",
                "description": "Neubau Großhandelslager EDEKA",
                "service": "Erstellung von Ausführungsdaten, DGM für das Außengelände OK Fertig, Fahrbahn OK Fertig, Parkplätze, Versickerungsmulden und Rollbehälter"
               },
                {
                "project": " BV Kruckel - Dauersberg",
                "contractor": "Arning Bauunternehmung GmbH",
                "description": "Neubau Hochspannungleitungen ca. 18 km.",
                "service": "Erstellung von Ausfürungsdaten, DGM für ca. 60 Mastfundamente und Gründungen erstellt,Erstellung von Erdbauberichten zur Massenabrechnung="
               },
        ]
        }
    }
}
</script>