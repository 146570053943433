import global from "./globals"

window.addEventListener("on-send-email", (e) => {
    let g = new global();
    g.sendRequest(e.detail.message);
})

window.addEventListener("on-download-brochure", () => {
    let g = new global();
    g.getBrochure();
})