<template>
    <div>
        <v-card flat class="mt-16 mx-md-10 mx-8"> 
             <v-row>
                <v-col class="text-end mr-6 mb-1">
                     <a href="#/projects/premeasure" class="referenceLink"> >> zu den Projekten</a>
                </v-col>
            </v-row>
            <v-row class="mb-5"> 
                    <v-card  flat width="100%">
                        <v-img class="white--text align-end mx-7"
                                height="200px"
                                src="@/assets\images\301q_Vormessen.jpg">
                            <v-card-title>
                                <v-col lg="9" class="text-body-2 text-md-h5 text-lg-h4">
                                    Vormessen für Stopfmaschinen
                                </v-col>
                            </v-card-title>
                        </v-img>
                    </v-card>
            </v-row>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(data, i) in componentData"
                                :key="i">
                                <v-expansion-panel-header> 
                                        <v-card flat>
                                            <v-card-title v-html="data.title" class="text-body-1 text-md-h6 wrap-normal">
                                            </v-card-title>
                                            <v-card-text v-html="data.shortText">
                                            </v-card-text>
                                        </v-card>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col v-html="data.longText">
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'servicesPremeasure',
  components: {
  },
  data() {
      return {
        title: "",
        componentData: [
            {
                'title': "Was ist „Vormessen?",
                'shortText': 'Die Erfassung der Ist-Gleislage vor jedem Stopfgang (H/V-Gang, 1.Stabilisierung, 2.Stabilisierung) wird unter dem Begriff Vormessen zusammengefasst. <br>' +
                             'Ob man es nun GEDO CE nennt oder Hand-EM-SAT das System ist das gleiche. Präzise und effektiv.',
                'longText': 'Die Erfassung der Ist-Gleislage vor jedem Stopfgang (H/V-Gang, 1.Stabilisierung, 2.Stabilisierung) wird unter dem Begriff Vormessen zusammengefasst.<br><br>' +  

                            'Die Qualität der Gleislage, die mit einer Stopfmaschine erreicht werden kann, ist nur so gut wie die Vorgabe der Korrekturwerte. Daher kommt der präzisen und langwellig richtigen Ermittlung dieser Korrekturwerte eine große Bedeutung zu. Je höher die Anfangsqualität des Gleises, desto geringer sind die späteren Instandhaltungskosten für den Betreiber. Der Automatisierte Leit-Computer der sich auf nahezu allen Stopfmaschinen befindet, erlaubt ein direktes digitales Umsetzen dieser Korrekturwerte. Das hierdurch mögliche computergesteuerte Manövrieren der Stopfpickel und somit des Gleises in Lage und Höhe ermöglicht einerseits eine deutlich bessere Gleislage und andererseits höhere Stopfleistungen der Maschinen.<br><br>' +

                            'Als führender Dienstleister in diesem Sektor haben wir Ihnen das Thema Vormessen für Stopfmaschinen auch hier nochmal ganz neu zusammengestellt:<br><br>' +

                            '<a href="www.vormessen.de">www.vormessen.de</a>!<br><br>' +

                            'Ein Klick - Eine andere Dimension!'
            },
            {
                'title': "Gedo CE vorsys",
                'shortText': 'Mit Hilfe zweier Messwagen wird auf Basis eines Trassenplans (Gleisvermarkungsplan) oder digital vorhandener Trassendaten ein rein mastfeldbezogenes Arbeiten ermöglicht.',
                'longText': 'Mit Hilfe zweier Messwagen wird auf Basis eines Trassenplans (Gleisvermarkungsplan) oder digital vorhandener Trassendaten ein rein mastfeldbezogenes Arbeiten ermöglicht. Als Messgerät dient hier ein hochgenauer Tachymeter der Trimble S-Serie, der einerseits als Festpunktmessgerät und andererseits als Alignierinstrument zur Festlegung der Bezugssehne für die Pfeilhöhenmessung dient. Somit kann schnell, präzise und wirtschaftlich ein Soll-Ist-Vergleich zum projektierten Trassenverlauf vorgenommen werden.<br><br>' +

                            'Dieses Messverfahren ermittelt wie der Gleismesstriebwagen EM-SAT die Hebe- und Verschiebewerte im Gleis. Daher auch die Bezeichnung Hand-EM-SAT. Weiterhin ist die Dokumentation der Überhöhung, der Spurweite wie auch die Anzeichnung von Bogenhauptpunkten möglich.<br><br>' +

                            'Die Messgeschwindigkeit von bis zu 1200 Metern pro Stunde, einer Auswertezeit von maximal 30 Minuten pro Kilometer sowie der äußerst flexiblen Handhabung der Messwagen lassen dieses Messverfahren als eines der innovativsten Technologien der letzten Jahre erscheinen. Das Einsparen von Gleissperrungen, das Unnötigwerden von logistischen Kraftakten durch das Schienennetz der DB AG wird von immer mehr Gleisbaufirmen erkannt und genutzt.<br><br>' +

                            'In diese Messtechnologie haben wir all unsere Erfahrung auf dem Sektor der Gleisvermessung, sowie dem zugewonnen Know-How im Ansteuern von Großmaschinen mit Hilfe des ALC eingebracht.<br><br>' + 

                            'Eine optimale Betreuung der Stopfmaschine durch unsere hochmotivierten und sehr gut geschulten Mitarbeiter garantiert Ihnen einerseits deutlich bessere Ergebnisse der Gleislage als beim manuellen Vormessen und andererseits ein Messsystem welches logistisch sehr variabel in den Bauablauf zu integrieren ist.<br><br>' +

                            'Für diese Messungen ist keine Vollsperrung nötig, da der Hand EM-SAT schnell aus dem Gleis geräumt werden kann. Kurze Zugpausen reichen aus um die Messtechnik zu nutzen!<br><br>' + 

                            'Als führender Dienstleister in diesem Sektor haben wir Ihnen das Thema Vormessen für Stopfmaschinen auch hier nochmal ganz neu zusammengestellt:<br><br>' + 

                            '<a href="www.vormessen.de">www.vormessen.de</a>!<br><br>' +

                            'Ein Klick - Eine andere Dimension!'
            },
            {
                'title': 'Gedo CE',
                'shortText': 'Bei dieser Variante wird lediglich ein Messwagen im Gleis aktiviert. Die Orientierung im Festpunktfeld erfolgt mit Hilfe eines außerhalb des Gleiskörpers im Festpunktfeld stationierter Tachymeter.',
                'longText': 'Bei diese Variante wird lediglich ein Messwagen im Gleis aktiviert. Die Orientierung im Festpunktfeld erfolgt mit Hilfe eines außerhalb des Gleiskörpers im Festpunktfeld stationierter Tachymeter. Mit dieser Kombination lässt sich schnell und präzise ein Gleisaufmaß durchführen und zeitgleich sowohl die Überhöhung als auch die Spurweite erfassen.<br><br>' + 

                            'Als führender Dienstleister in diesem Sektor haben wir Ihnen das Thema Vormessen für Stopfmaschinen auch hier nochmal ganz neu zusammengestellt:<br><br>' +

                            '<a href="www.vormessen.de">www.vormessen.de</a>!<br><br>' +

                            'Ein Klick - Eine andere Dimension!'
            },
            {
                'title': 'Ersatzmessung nach RiL 824.053',
                'shortText': 'Mit Hilfe eines Mehrkanalschreibers (MKS) dokumentiert die Stopfmaschine die erzielte Gleislage. Hierbei gilt es die Parameter Längshöhe, Verschiebung, relative Höhenlage, Verwindung und Überhöhung aufzuzeichnen.',
                'longText': 'Mit Hilfe eines Mehrkanalschreibers (MKS) dokumentiert die Stopfmaschine die erzielte Gleislage. Hierbei gilt es die Parameter Längshöhe, Verschiebung, relative Höhenlage, Verwindung und Überhöhung aufzuzeichnen. Fällt dieser Mehrkanalschreiber aufgrund von technischen Gründen aus, so ist nach RiL 824.0530 eine Ersatzmessung per Hand durchzuführen.<br><br>' + 

                            'Mit Hilfe der GEDO CE-Ausrüstung und eigens entworfener Berechnungsformulare sind wir in der Lage diese Ersatzmessung kostengünstig, das heisst ohne ein nochmaliges Anreisen der Stopfmaschine durchzuführen.<br>' + 
                            'Das Ergebnis ist das lt. RiL 824.0530 zur Abnahme zu übergebende Formular.<br><br>' +

                            'Als führender Dienstleister in diesem Sektor haben wir Ihnen das Thema Vormessen für Stopfmaschinen auch hier nochmal ganz neu zusammengestellt:<br><br>' +

                            '<a href="www.vormessen.de">www.vormessen.de</a>!<br><br>' +

                            'Ein Klick - Eine andere Dimension!'
            },
            {
                'title': 'MKS-Schrieb',
                'shortText': 'Eine Alternative zur Dokumentation des Mehrkanalschreibers der Stopfmaschine.',
                'longText': 'Eine Alternative zur Dokumentation des Mehrkanalschreibers der Stopfmaschine. Die vom Messsystem GEDO CE erfassten Messwerte des Gleises ermöglichen uns einen MKS-Schrieb zu erzeugen, der dem der Stopfmaschine gleichkommt.<br><br>' +
                                'Der MKS-Schrieb ist abnahmerelevant.<br><br>'+

                                'Als führender Dienstleister in diesem Sektor haben wir Ihnen das Thema Vormessen für Stopfmaschinen auch hier nochmal ganz neu zusammengestellt:<br><br>' + 

                                '<a href="www.vormessen.de">www.vormessen.de</a>!<br><br>' +

                                'Ein Klick - Eine andere Dimension!'
            },
        ]
      }
  },
}
</script>

<style scoped>
     .referenceLink {
        letter-spacing: 1px;
    }
    .referenceLink:hover {
        cursor: pointer;
    }
</style>
