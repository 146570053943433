<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card :height="cardheight + 'vh'" max-width="85vw" elevation="13" class="scroll">
                <a :href="'/#/projects/' + service.href">
                    <v-img :src="serviceWithPicture.image" contains min-height="100%" max-height="100%"></v-img>
                    <v-fade-transition>
                        <v-overlay
                            v-if="hover || isMobile"
                            absolute
                            color="#000000"
                            opacity="0.2"
                            class="text-center">
                                <v-card-text class="mb-2 text-lg-h4 text-h6" align="center"> {{ service.title}} </v-card-text>
                                <v-card-text class="text-center d-none d-md-block" style="color:white">{{service.text}}
                                </v-card-text>
                        </v-overlay>
                    </v-fade-transition>
                </a>
            </v-card> 
        </template>
    </v-hover>
</template>

<script>
export default {
    name:"SingleReference",
    props: ["service"],
    computed: {
        serviceWithPicture() {
            return {
                ...this.service,
                image: this.service.image && require('../../assets/images/' + this.service.image)
            }
        },
        cardheight() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 70
                    case 'sm': return 70
                    case 'md': return 70
                    case 'lg': return 65
                    case 'xl': return 65
                    default: return 45;
            }
        },
         isMobile() {
          if(/Android|webOS|iPhone|ipad|tablet|kindle|playbook|silk|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        switchWindowLocation(href){
            window.location.href = '/#/projects/' + href;
        }
    }
}
</script>

<style>