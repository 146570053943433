import LandingPageView from '../components/LandingPage/LandingPage.vue'
import ServiceView from '../components/Services/Services.vue'

import News from '../components/News/News.vue'

import servicesPremeasure from '../components/Services/servicesPremeasure.vue'
import servicesTrackConstruction from '../components/Services/servicesTrackConstruction.vue'
import servicesEngineering from '../components/Services/servicesEngineering.vue'
import servicesMachineControl from '../components/Services/servicesMachineControl.vue'
import servicesRoadConstruction from '../components/Services/servicesRoadConstruction.vue'
import servicesSpecialSolutions from '../components/Services/servicesSpecialSolutions.vue'

import References from "../components/references/References"
import referencesTrackConstruction from '../components/references/referencesTrackConstruction.vue'
import referencesEngineering from '../components/references/referencesEngineering'
import referenceSpecialSolutions from '../components/references/referencesSpecialSolutions.vue'
import referencesPremeasure from '../components/references/referencesPremeasure.vue'
import referencesRoadConstruction from '../components/references/referencesRoadConstruction.vue'
import referencesMachineControl from '../components/references/referencesMachineControl.vue'

import AboutUs from '../components/AboutUs/AboutUs.vue'

import Datenschutz from '../../src/components/Other/Datenschutz.vue'
import Downloads from '../../src/components/Other/Downloads.vue'
import Impressum from '../../src/components/Other/Impressum.vue'
import Career from '../../src/components/Other/Career.vue'
import Legal from '../../src/components/Legal/Legal.vue'


const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPageView,
    },
    {
        path: '/services',
        name: 'Service',
        displayName:'Leistungen',
        component: ServiceView,
        hasSubRoutes: 1,
    },
    {
        path: '/services/premeasure',
        name: 'servicesPremeasure',
        displayName:"Vormessen",
        component: servicesPremeasure
    },
    {
        path: '/services/trackconstruction',
        name: 'servicesTrackConstruction',
        displayName:"Gleisbau",
        component: servicesTrackConstruction
    },
    {
        path: '/services/engineering',
        name: 'servicesEngineering',
        displayName:"Ingenieurbau",
        component: servicesEngineering
    },
    {
        path: '/services/machinecontrol',
        name: 'servicesMachineControl',
        displayName:"Maschinensteuerung",
        component: servicesMachineControl
    },
    {
        path: '/services/roadconstruction',
        name: 'servicesRoadConstruction',
        displayName:"Straßenbau",
        component: servicesRoadConstruction
    },
    {
        path: '/services/specialsolutions',
        name: 'servicesSpecialSolutions',
        component: servicesSpecialSolutions
    },
    {
        path: '/projects',
        name: 'references',
        displayName:'Projekte',
        component: References,
        hasSubRoutes: 1,
    },
    {
        path: '/projects/trackconstruction',
        name: 'referencesTrackConstruction',
        component: referencesTrackConstruction
    },
    {
        path: '/projects/engineering',
        name: 'referencesEngineering',
        component: referencesEngineering
    },
    {
        path: '/projects/specialsolutions',
        name: 'referenceSpecialSolutions',
        component: referenceSpecialSolutions
    },
    {
        path: '/projects/premeasure',
        name: 'referencesPremeasure',
        component: referencesPremeasure
    },
    {
        path: '/projects/roadconstruction',
        name: 'referencesRoadConstruction',
        component: referencesRoadConstruction
    },
    {
        path: '/projects/machinecontrol',
        name: 'referencesMachine',
        displayName:"Maschinensteuerung",
        component: referencesMachineControl
    },
    {
        path: '/news/:id?',
        name: 'News',
        component: News
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: Datenschutz
    },
    {
        path: '/career',
        name: 'Karriere',
        component: Career
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: Downloads
    },
    {
        path: '/aboutus',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/legal',
        name: 'Legal',
        component: Legal
    },
  ]

export default routes;