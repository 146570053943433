<template>
    <div>
        <v-row>
        <!-- <v-col lg="12" xs="12" class="pt-0" >
                <v-img width="100vw" :height="height" src="@/assets\images\engineering-image-14.jpg">
                    <v-overlay absolute :value="overlay" opacity="0.7" >
                        <v-row class="d-flex flex-column justify-center"> 
                            <v-col class="text-h6 text-lg-h5 text-center">
                                    Projekt des Monats:
                            </v-col>
                            <v-col class="text-h6 text-lg-h3 text-center">
                                {{ monthProject.title}}
                            </v-col>
                            <v-col class="mehr text-a1 text-lg-s1 text-center">
                                <a :href="'/#/news/' + monthProject.id" >mehr..</a>
                            </v-col>
                        </v-row>
                    </v-overlay>
                </v-img>
        </v-col> -->
        <v-col lg="12" xs="12" class="pt-0" >
                <v-img width="100vw" :height="height" src="@/assets\images\101_20180905-IMG_4318_LG_TG.jpg">
                    
                </v-img>
        </v-col>
        </v-row>
    </div>
</template>

<script>
import monthProjects from '../../configs/monthProjects';

export default {
  name: 'LandingPageBase',
  data() {
      return {
          overlay: true,
          monthProject : {},
      }
  },
  computed: {
      width() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 600
            case 'sm': return 800
            case 'md': return 1000
            case 'lg': return 1200
            case 'xl': return 1600
            default: return 800;
        }
      },
       height() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 600
            case 'sm': return 600
            case 'md': return 600
            case 'lg': return 1000
            case 'xl': return 1000
            default: return 1000;
        }
      },
  },
  mounted() {
        let monthProject;
        let year = 0;
        let month = 0;
        for(let i in monthProjects) {
            let arr= monthProjects[i].date.split("/");
            if(arr[1] > year ) {
                if (arr[0] > month) {
                    month = arr[0];
                    year = arr[1];
                    monthProject = monthProjects[i];
                }
            }
        }
        this.monthProject = monthProject;
    }
}
</script>

<style scoped>
    .text {
        color:white;
        float: right;
        letter-spacing: 3px;
    }
    .mehr:hover {
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
        transition: 0.6s;
    }
    a {
        color: white !important;
    }
</style>
