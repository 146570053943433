<template>
    <v-container class="mt-md-16">
        <v-row class="title ma-0" justify="center">
            <span class="text text-h5 text-lg-h3"> Leistungen </span>
        </v-row>
        <v-row class="pt-7 pa-md-16">
            <v-col lg="4" md="6" xs="12" v-for="(service, i) in services" :key="i" align="center">
                <single-service 
                style="max-height: 500px"
                :doText="false"
                :service="service"></single-service>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import serviceArray from "../../../configs/services"
import SingleService from './singleService.vue';

export default {
    name:"Portfolio",
    components: {
        SingleService
    },
    computed: {
        perpage () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 1
                case 'sm': return 1
                case 'md': return 2
                case 'lg': return 3
                case 'xl': return 3
                default: return 3;
            }
        },
       
    },
    data () {
        return {
            services: serviceArray,
        }
    }
}
</script>

<style scoped>
    .shadow {
        -webkit-box-shadow: 0px 6px 30px 6px rgba(216,20,6,0.8);
        -moz-box-shadow: 0px 6px 30px 6px rgba(216,20,6,0.8);
        box-shadow: 0px 6px 30px 6px rgba(216,20,6,0.8);
        border: 1px solid red;
    }
    .text {
        font-family: 'Inter', sans-serif;
    }
    .scroll {
        overflow-y: auto;
    }
</style>