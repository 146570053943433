<template>
<v-card flat class="pa-0 ma-0">
    <v-row>
        <v-spacer></v-spacer>
        <v-col lg="6" xs="12">
                <DescBox></DescBox>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</v-card>
</template>


<script>
import DescBox from "./DescBox";
// import NewsCarousel from '../../News/NewsCarousel'


export default {
    name:"WhoWeAre",
    components:{
        DescBox,
       // NewsCarousel
    },
    data() {
       return {

       }
    },
    methods: {
    },
    mounted() {
    },
   
}
</script>

<style scoped>
    .text {
        font-family: 'Inter', sans-serif;
    }
    .descr {
        font-size: 1.2em;
        line-height: 1.5em;
    }
    .longtext {
        background: #302F30;
    }
    
</style>
