import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import VueFullPage from 'vue-fullpage.js'
import VueCarousel from 'vue-carousel';
import VueLayers from 'vuelayers';
import VueExpandableImage from 'vue-expandable-image'



import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import "../scripts/getData";

Vue.use(VueToast);

Vue.config.productionTip = false
Vue.use(VueFullPage);
Vue.use(VueCarousel);
Vue.use(VueLayers);
Vue.use(VueExpandableImage);


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
